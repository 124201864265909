import React, { useLayoutEffect, useState } from "react";

import { loadStripe } from "@stripe/stripe-js";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import "./payment.scss";
import { useForm } from "react-hook-form";
import { CouponCommander } from "../../store/commanders/coupon.commander";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faLongArrowAltLeft,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import SubscriptionComander from "../../store/commanders/subscription.comander";
import { toast } from "react-toastify";
import paymentsLogo from "../../assets/payments-logo.png";
import { Icon } from "@fortawesome/fontawesome-svg-core";
import { useSelector } from "react-redux";
import { stripe_public_key } from "../../settings/setting";
const stripePromise = loadStripe(stripe_public_key);

const COURSE_PRICE = 24.99;

const coupon_message = (name, discount) => {
  return discount == 0
    ? `Your coupon is valid and has been provided by ${name}.`
    : `Your coupon is valid and has been provided by ${name}. This coupon granted you a ${discount}% discount.`;
};

const CheckoutForm = ({ coupon, getAmount }) => {
  const stripe = useStripe();
  const elements = useElements();

  const options = {
    style: {
      invalid: {
        color: "#9e2146",
        borderColor: "#9e2146",
      },
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    SubscriptionComander.cmd_proccess_payment(
      stripe,
      elements.getElement(CardNumberElement),
      coupon.coupon_key,
      coupon.discount
    );
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-5">
        <label>
          <div className="row align-items-center">
            <span className="col">Card number</span>

            <span className="col">
              <img src={paymentsLogo} alt="" className="payment-logos" />
            </span>
          </div>

          <CardNumberElement options={{ ...options, showIcon: true }} />
        </label>

        <div className="row">
          <div className="col-sm">
            <label>
              Expiration
              <CardExpiryElement options={options} />
            </label>
          </div>
          <div className="col-sm">
            <label>
              CVC
              <CardCvcElement options={options} />
            </label>
          </div>
        </div>
      </div>

      <div className="enrollment-btns row">
        {/* <div className="col">
          <a href="https://lms.playsafelysports.com/account">
            <FontAwesomeIcon icon={faLongArrowAltLeft as Icon} /> Back
          </a>
        </div> */}

        <div className="col">
          <button
            type="submit"
            disabled={!stripe || !elements}
            className="cf-navbar__links--link cf-navbar__links--link-primary"
          >
            Pay <span> {getAmount()}$</span>
          </button>
        </div>
        <div className="col"></div>
      </div>
    </form>
  );
};

const PaymentPage = () => {
  const user = useSelector((state: any) => state.application.user);

  const {
    register,
    handleSubmit: handleCouponSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [coupon, setPaymentMeta] = useState<any>({});
  const [processingCoupon, setProcessingCoupon] = useState(false);
  const getAmount = () => {
    return coupon && coupon.discount
      ? (COURSE_PRICE * ((100 - coupon.discount) / 100)).toFixed(2)
      : COURSE_PRICE.toFixed(2);
  };

  useLayoutEffect(() => {
    if (user.id_coupon && user.id_coupon !== "undefined") {
      onSubmitCoupon({ coupon_key: user.id_coupon });
      setValue("coupon_key", user.id_coupon);
    }
  }, [user.userName]);

  const onSubmitCoupon = async (data) => {
    setProcessingCoupon(true);
    const result: any = await CouponCommander.cmd_validate_coupon(
      data.coupon_key ? data.coupon_key : ""
    );
    if (result && result.error) {
      setProcessingCoupon(false);
      return;
    }
    setProcessingCoupon(false);

    if (result.discount === 100) {
      toast(
        `Your coupon is valid and your course has been paid for by ${result.organization_name}. Continue to start your course.`
      );
      CouponCommander.cmd_redeem_coupon(
        data.coupon_key
          ? {
              coupon_key: data.coupon_key,
              id_sales: user.id_sales,
              id_organization: user.id_organization,
            }
          : ""
      );
    } else {
      setPaymentMeta({
        coupon_key: data.coupon_key,
        discount: result.discount,
        organization_name: result.organization_name,
      });
      toast(coupon_message(result.organization_name, result.discount));
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <div className="payment-container">
        <form onSubmit={handleCouponSubmit(onSubmitCoupon)}>
          <div className="row align-items-start">
            <div className="col">
              <input
                className="coupon-input"
                placeholder="Coupon"
                defaultValue=""
                {...register("coupon_key", { required: true })}
              />

              {processingCoupon ? (
                <div className="coupon-text">
                  <FontAwesomeIcon
                    icon={faSpinner as Icon}
                    className="fa-pulse"
                  />
                </div>
              ) : null}
              {coupon && coupon.coupon_key ? (
                <div className="coupon-text">
                  <FontAwesomeIcon
                    icon={faCheck as Icon}
                    className="coupon-text-icon"
                  />
                  <span>
                    {coupon_message(coupon.organization_name, coupon.discount)}
                  </span>
                </div>
              ) : null}
            </div>

            <div className="col">
              <input
                className="cf-navbar__links--link cf-navbar__links--link-primary redeem-btn"
                type="submit"
                value="Redeem"
                disabled={processingCoupon}
              />
            </div>
          </div>
        </form>
        <CheckoutForm coupon={coupon} getAmount={getAmount} />
      </div>
    </Elements>
  );
};

export default PaymentPage;
