export const URL_PATHS = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  PAYMENT: "/payment",
  ACTIVE_ACCOUNT: "/activate",
  RESET_PASSWORD: "/reset-password",
  FORGOT_PASSWORD: "/forgot-password",
  //ENROLMENT: '/enrolment',
  ACCOUNT: "/account",
  COURSE: "/course",
  APP: "/",
};
