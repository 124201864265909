class EventManager{
    listener:any;
    constructor(){
        this.listener={}
    }
    addListener=(event,listener)=>{
        if (!this.listener[event])this.listener[event]=[];
        this.listener[event].push(listener)
    }
    removeListener=(event,listener)=>{
        if (!this.listener[event]) return;
        const index= this.listener[event].findIndex((item=>item===listener))
        if (index>-1)
        this.listener[event].slice(index,1)
    }
    send=(event,data)=>{
        const listeners=this.listener[event]
        if (!listeners || listeners.length==0) return
        listeners.forEach(ls => {ls(data)
            
        });
    }
}
const instance=new EventManager();
export default instance;