import Actions from "../consts";
import {getNextNode, searchTree, updatePropertyById, updateRowInTree} from "../helper/tree.helper";

export const course = (state: any = {}, action: any = {}) => {
    switch (action.type) {
        case Actions.GET_COURSE:
            const courseData = action.payload
            if (courseData.lastseen) {
                const lastSeen = searchTree(courseData, courseData.lastseen)
                return {...state, data: courseData, selectedSection: lastSeen};
            }

            return {...state, data: courseData, selectedSection: courseData};
        case Actions.UPDATE_LAST_SEEN:
            return {...state, data: action.payload};
        case Actions.GET_CERTIFICATES:
            return {...state, certificates: action.payload};
        case Actions.SET_COURSE_SECTION:
            return {...state, selectedSection: action.payload};

        case Actions.MARK_LESSON_FINISH:
            const updatedData = updatePropertyById(action.payload.id, state.data, 'finish', true)
            return {...state, data: updatedData};
        case Actions.GO_LAST_SEEN:
            if (state.data.lastSeen) {
                const lastSeen = searchTree(state.data, state.data.lastSeen)
                return {...state, selectedSection: lastSeen};
            }
            return state
        case Actions.NEXT_LESSON:
            const lastSeen = getNextNode(state.data, state.selectedSection.id, state.data)
            return {...state, selectedSection: lastSeen}
        case Actions.SUBMIT_QUIZ:
            const updatedCourseData = updateRowInTree(state.data, action.payload.id, {...state.selectedSection, ...action.payload})
            const updatedSelected = searchTree(updatedCourseData, action.payload.lastseen)
            return {...state, data: updatedCourseData, selectedSection: updatedSelected};
        case Actions.RETRY_QUIZ:
            const newquiz = searchTree( state.data, action.payload.id)
            newquiz.status='unknown';
            return {...state, data: state.data, selectedSection: newquiz};
        default:
            return state;
    }
};