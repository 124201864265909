import React, { useEffect, useRef, useState } from "react";

import "./course.overview.cmp.scss";
import courseComander from "../../../store/commanders/course.comander";
import { Line } from "rc-progress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularProgressCmp from "../../common/progressbar/circular.progress.cmp";
import { PrintableCertificate } from "../../certficate/certificate";
import CourseSurveyCmp from "../course.survey/course.survey.cmp";
import ModelCmp from "../../model/model.cmp";

const UserCertificate = ({ certificate, userName, state }) => {
  return (
    <div className={"user-certificate-row"}>
      <div>{new Date(certificate.comp_date).toDateString()}</div>
      <div className="print-user-cert-container">
        <PrintableCertificate
          courseStatus={"pass"}
          userName={userName}
          state={state}
          completedDate={new Date(certificate.comp_date).toDateString()}
          className={"print-user-cert"}
          hideIcon={true}
        />
      </div>
    </div>
  );
};

const CourseOverviewCmp = ({
  selected,
  userName,
  state,
  onSelect,
  showFeedbackForStudent,
  showCongrats,
  submitFeedback,
  recertifyAction,
  userCertificates,
}) => {
  const [showFeedback, setShowFeedback] = useState(false);
  const surveyRef = useRef(null);

  const [showCongratsModel, setShowCongratsModel] = useState(false);

  useEffect(() => {
    setShowCongratsModel(showCongrats);
  }, [showCongrats]);

  const renderModuleResult = (course, onSelect) => {
    return course.children.map((module) => {
      const icon = module.status == "pass" ? "check" : "ban";
      const color = module.status == "pass" ? "green" : "var(--primary)";
      return (
        <div className="module-content" key={module.id}>
          <div
            className="module-content-title"
            onClick={() => onSelect(module)}
          >
            {module.title}{" "}
          </div>
          <div style={{ margin: 10, width: 400 }} className="d-md-block d-none">
            <Line
              percent={module.grades ? module.grades : 0}
              trailColor="silver"
              strokeWidth={4}
              trailWidth={4}
              strokeColor={color}
            />
          </div>

          <div className="text-center">
            <FontAwesomeIcon
              size="2x"
              icon={icon}
              className="icon"
              color={color}
            />
          </div>
          <div style={{ margin: 10, color: color, width: 50 }}>{` ${module.grades ? module.grades : 0
            } %`}</div>
        </div>
      );
    });
  };

  const finishedQuizzesCount: number =
    selected.progress === 0
      ? 0 / selected.children.length
      : Math.round((selected.progress * selected.children.length) / 100);

  const recertify = () => {
    recertifyAction();
  };
  const renderNotification = () => {
    if (
      selected &&
      selected.finish &&
      userCertificates &&
      userCertificates.length
    ) {
      const completed_date =
        userCertificates[userCertificates.length - 1].comp_date;
      const msBetweenDates = Math.abs(
        new Date(completed_date).getTime() - new Date().getTime()
      );
      const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);
      switch (true) {
        case daysBetweenDates >= 335:
          return (
            <div className="notification_container">
              Your certification is{" "}
              {daysBetweenDates >= 365 ? "expired" : "about to be expired"}.{" "}
              <button
                className={"notification_container--btn"}
                onClick={recertify}
              >
                Recertify now
              </button>
            </div>
          );
        default:
          return null;
      }
    }
  };

  return (
    <div className="text-center marks-bg">
      {renderNotification()}

      {showCongratsModel ? (
        <ModelCmp
          name={"Congratulation you have completesd the course"}
          bodyComponent={
            <div className="text-center">
              To be able to continually improve, we need your feedback <br /> Go
              to survey.
            </div>
          }
          onCancel={() => setShowCongratsModel(false)}
          onSend={() => setShowFeedback(true)}
        />
      ) : null}

      {showFeedback && selected && selected.id ? (
        <ModelCmp
          name={"Survey"}
          bodyComponent={
            <CourseSurveyCmp
              innerRef={surveyRef}
              courseId={selected.id}
              submitFeedback={submitFeedback}
              afterSubmitting={() => setShowFeedback(false)}
            />
          }
          onCancel={() => setShowFeedback(false)}
          onSend={() =>
            surveyRef.current.dispatchEvent(
              new Event("submit", {
                cancelable: true,
                bubbles: true,
              })
            )
          }
        />
      ) : null}

      <div className="module-title">Welcome to the {selected.title}</div>

      <div className="module-description">{selected.description}</div>
      <div className="course-summary-content">
        <div style={{ maxWidth: 300 }}>
          <CircularProgressCmp
            grades={(finishedQuizzesCount / selected.children.length) * 100}
            text={`${finishedQuizzesCount}/${selected.children.length}`}
            status={selected.status ? selected.status : 0}
          />
          <div className="module-title mt-4">
            {finishedQuizzesCount} of 11 quizzes complete
          </div>
        </div>

        <div className="course-completion-container">
          <div className="course-status">
            {selected.status == "pass"
              ? `Course Completed ${selected.last_completion} expires ${selected.expiration_date}`
              : `Course in Progress`}
          </div>

          <div className="mt-4">
            <PrintableCertificate
              courseStatus={selected.status}
              userName={userName}
              state={state}
              completedDate={selected.last_completion}
            />
          </div>
        </div>

        {userCertificates && userCertificates.length ? (
          <div className="cert-history">
            <h3>certifications History</h3>
            {userCertificates.map((certificate) => (
              <UserCertificate
                certificate={certificate}
                userName={userName}
                state={state}
              />
            ))}
          </div>
        ) : null}
        <div className="module-button-container">
          <button
            className="cf-navbar__links--link cf-navbar__links--link-primary"
            onClick={() => {
              courseComander.ac_next_lesson();
              courseComander.cmd_update_lastSeen(selected.id);
            }}
          >
            Resume
          </button>
          {selected && selected.status === "pass" && showFeedbackForStudent ? (
            <button
              className="cf-navbar__links--link cf-navbar__links--link-primary ml-4"
              onClick={() => {
                setShowFeedback(true);
              }}
            >
              Give Feedback
            </button>
          ) : null}
        </div>
        <div className="results-container">
          {renderModuleResult(selected, onSelect)}
        </div>
      </div>
    </div>
  );
};
export default CourseOverviewCmp;
