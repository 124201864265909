import React, { useEffect, useState } from "react";

import "./course.quiz.cmp.scss";
import { useForm } from "react-hook-form";
import courseComander from "../../../store/commanders/course.comander";
import CircularProgressCmp from "../../common/progressbar/circular.progress.cmp";
import { ac_get_answers } from "../../../store/actions/course.action";

const CourseQuestionCmp = ({
  item,
  register,
  errors,
  reviewMode,
  rightAnswers,
}) => {
  const renderPossibleAnswers = (answers) => {
    const nodotId = item.id.replaceAll(".", "#");
    return answers.map((answer, index) => {
      return (
        <label className="quiz-answer" id={answer.id}>
          <input
            className="quiz-radiobox"
            type="radio"
            value={answer.id}
            id={answer.id}
            defaultChecked={rightAnswers[item.id] === answer.id}
            {...register(nodotId, {
              required: true,
              disabled: reviewMode,
            })}
          />
          {answer.title}
        </label>
      );
    });
  };

  return (
    <div className="quiz-question" id={item.id}>
      <div className="quiz-question-title">{item.title}</div>
      {renderPossibleAnswers(item.possibleAnswers)}
      {errors[item.title] && <span>This field is required</span>}
    </div>
  );
};

const CourseQuizCmp = ({ selected, isUserAdmin }) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    control,
    setValue,
  } = useForm();
  const [reviewMode, setReviewMode] = useState(false);
  const [reviewedAnswers, setReviewedAnswers] = useState({});
  const [loadingAnswers, setLoadingAnswers] = useState(false);

  const onSubmit = (data) => {
    const userAnswers = {};
    for (const [index, [key, value]] of Object.entries(Object.entries(data))) {
      userAnswers[key.replaceAll("#", ".")] = value;
    }
    courseComander.ac_submit_quiz(userAnswers);
  };

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    async function getAnswers() {
      try {
        const response = await ac_get_answers(selected.id);
        setReviewedAnswers(response ? response.response_data : {});
      } catch (e) {}
      setLoadingAnswers(false);
    }

    if (reviewMode && selected) {
      setLoadingAnswers(true);
      getAnswers();
    }
  }, [reviewMode, selected.id]);

  useEffect(() => {
    if (reviewMode && selected) {
      for (const answer in reviewedAnswers) {
        setValue(answer.replaceAll(".", "#"), reviewedAnswers[answer]);
      }
    }
  }, [reviewedAnswers]);

  const renderQuestions = (questions) => {
    const shuffeled = [
      ...questions.sort(() => {
        const randomTrueOrFalse = Math.random() > 0.5;
        return randomTrueOrFalse ? 1 : -1;
      }),
    ];
    return shuffeled.map((item) => (
      <CourseQuestionCmp
        item={item}
        register={register}
        errors={errors}
        reviewMode={reviewMode}
        rightAnswers={reviewedAnswers}
      />
    ));
  };

  return (
    <div className="quiz-container">
      <div className="quiz-title">{selected.title}</div>
      {selected.children && (selected.status == "unknown" || reviewMode) ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          {loadingAnswers ? (
            <div className="loader loader-center"></div>
          ) : (
            renderQuestions(selected.children)
          )}

          <div className="quiz-button-container">
            {reviewMode ? (
              <button
                className="cf-navbar__links--link cf-navbar__links--link-primary"
                onClick={() => courseComander.ac_next_lesson()}
              >
                Next
              </button>
            ) : (
              <div className={"quiz-btns"}>
                <input
                  type="submit"
                  className="cf-navbar__links--link cf-navbar__links--link-primary"
                  value={"Submit"}
                />
                {isUserAdmin ? (
                  <button
                    className="cf-navbar__links--link cf-navbar__links--link-primary pass-quiz-btn"
                    onClick={() => courseComander.cmd_admin_pass_quiz()}
                  >
                    Pass
                  </button>
                ) : null}
              </div>
            )}
          </div>
        </form>
      ) : null}

      {(selected.status === "pass" || selected.status === "fail") &&
      !reviewMode ? (
        <div className="grade-percentage">
          <div className="grade-percentage__shape">
            <CircularProgressCmp
              grades={selected.grades}
              status={selected.status}
            />
          </div>
          <h1 style={{ color: selected.status === "pass" ? "green" : "red" }}>
            {selected.status === "fail" ? "incomplete" : selected.status}
          </h1>

          <h3 className=" u-mt-md">
            You answered {(selected.grades * selected.children.length) / 100} of{" "}
            {selected.children.length} Questions Correctly
          </h3>

          {selected.status === "pass" ? (
            <div className="quiz-btns u-mt-xl">
              <button
                className="cf-navbar__links--link cf-navbar__links--link-primary mr-5"
                onClick={() => setReviewMode(true)}
              >
                Review
              </button>
              <button
                className="cf-navbar__links--link cf-navbar__links--link-primary"
                onClick={() => courseComander.ac_next_lesson()}
              >
                Next
              </button>
            </div>
          ) : (
            <button
              className="cf-navbar__links--link cf-navbar__links--link-primary"
              onClick={() => courseComander.cmd_retry_quiz(selected)}
            >
              Retry
            </button>
          )}
        </div>
      ) : null}
    </div>
  );
};
export default CourseQuizCmp;
