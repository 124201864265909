const { REACT_APP_ENV } = process.env;

const current_settings =
  REACT_APP_ENV === "development"
    ? require("./dev.settings.json")
    : REACT_APP_ENV === "productionlocal"
    ? require("./prod.local.settings.json")
    : require("./prod.settings.json");

export const awsConfig = current_settings.awsConfig;

export const HOST = current_settings.HOST;
export const PLATFORM_HOST = current_settings.PLATFORM_HOST;

export const ALLOW_IMGCACHE = false;

export const stripe_public_key = current_settings.stripe_public_key;

export const mapboxgl_accessToken = current_settings.mapboxgl_accessToken;
