import { disabledTypes, FormInputTypes } from "./form.component";
import { emailValidator, requiredValidator } from "./validators.form";

import eventManager from "../../helpers/event.manager";
const gender = ["Male", "Female", "Other", "NA"];
const ORG_TYPE_OPTIONS = [
  { value: "none", label: "None" },
  { value: "Private school", label: "Private School" },
  { value: "Public school", label: "Public School" },
  { value: "Parks & recreation", label: "Parks & Recreation" },
  { value: "Youth Athletic Association", label: "Youth Athletic Association" },
  { value: "YMCA/YWCA", label: "YMCA/YWCA" },
  { value: "private league", label: "Private League" },
  { value: "Boys & Girls Club", label: "Boys & Girls Club" },
  {
    value: "Multi-sport Youth Association",
    label: "Multi-sport Youth Association",
  },
  {
    value: "Church/Religious Organization",
    label: "Church/Religious Organization",
  },
  { value: "Travel or Club Team", label: "Travel or Club Team" },
];
const states = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
];

export const parentFormConfig = [
  {
    group: [
      {
        label: "Personal Information",
        type: FormInputTypes.TITLE,
      },
    ],
  },
  {
    group: [
      {
        name: "first_name",
        label: "First Name",
        placeholder: "name",
        type: FormInputTypes.TEXT,
        validator: requiredValidator,
        className: "parent-form-input",
      },
      {
        name: "last_name",
        label: "Last Name",
        placeholder: "name",
        type: FormInputTypes.TEXT,
        validator: requiredValidator,
        className: "parent-form-input",
      },
      {
        name: "account_email",
        label: "Email",
        placeholder: "org@email.com",
        type: FormInputTypes.TEXT,
        validator: emailValidator,
        className: "parent-form-input",
        disabledCondition: {
          type: disabledTypes.dependsOnAnotherFiled,
          field: "username",
        },
      },
    ],
  },
  {
    group: [
      {
        name: "phone",
        label: "Cell Phone",
        placeholder: "(212) 555-1234",
        type: FormInputTypes.PHONE,
        className: "parent-form-input",

        validator: requiredValidator,
      },
      {
        name: "birth_date",
        label: "DBO",
        type: FormInputTypes.DATE,
        className: "parent-form-input",
      },
      {
        name: "gender",
        label: "Gender",
        placeholder: "",
        type: FormInputTypes.LOOKUP_SELECT,
        data: gender,
        validator: requiredValidator,
        className: "parent-form-input",
      },
    ],
  },

  {
    group: [
      {
        name: "optin_email",
        label: "Opt-in Email",
        type: FormInputTypes.CHECKBOX,
      },
    ],
  },
  {
    group: [
      {
        label: "Contact Details",
        type: FormInputTypes.TITLE,
      },
    ],
  },
  {
    group: [
      {
        name: "address_1",
        label: "Address",
        placeholder: "Address",
        style: { width: "100%", maxWidth: "600px" },
        type: FormInputTypes.TEXT,
        validator: requiredValidator,
        className: "parent-form-input",
      },
    ],
  },
  {
    group: [
      {
        name: "address_2",
        label: "",
        placeholder: "Address",
        style: { width: "100%", maxWidth: "600px" },
        type: FormInputTypes.TEXT,
        className: "parent-form-input",
      },
    ],
  },

  {
    group: [
      {
        name: "zip_code",
        label: "Zip Code",
        placeholder: "ZipCode",
        type: FormInputTypes.TEXT,
        maxLength: 8,
        validator: requiredValidator,
        intype: "number",

        className: "parent-form-input-short",
      },
      {
        name: "city",
        label: "City",
        placeholder: "City",
        type: FormInputTypes.TEXT,
        validator: requiredValidator,

        className: "parent-form-input-short",
      },
      {
        name: "state",
        label: "State",
        placeholder: "",
        type: FormInputTypes.LOOKUP_SELECT,
        data: states,
        validator: requiredValidator,
        onChange: (data) => {
          console.log("state change");
          eventManager.send("state.change", data);
        },
        className: "parent-form-input",
      },
      {
        name: "county",
        label: "County",
        placeholder: "",
        type: FormInputTypes.LOOKUP_SELECT,
        lookup: "counties",
        // validator: requiredValidator,
        className: "parent-form-input",
      },
    ],
  },
  {
    group: [
      {
        label: "What sport(s) do you coach?",
        type: FormInputTypes.TITLE,
      },
    ],
  },

  {
    group: [
      {
        name: "organization_name",
        label: "Organization Name",
        placeholder: "",
        type: FormInputTypes.TEXT,
        validator: requiredValidator,
        className: "parent-form-input",
      },
      {
        name: "organization_type",
        label: "Organization Type",
        placeholder: "",
        type: FormInputTypes.LOOKUP_SELECT,
        data: ORG_TYPE_OPTIONS.map((item) => item.value),
        validator: requiredValidator,
        className: "parent-form-input",
      },
    ],
  },

  {
    group: [
      {
        name: "sport",
        label: "Sports",
        placeholder: "User's sports",
        type: FormInputTypes.LOOKUP_MULTI_SELECT_REACT,
        lookup: "LK_SPORTS",
        isTextArray: true,
        format: (value) => value && value.map((v) => v.label),
        className:"parent-form-input"
      },
      {
        name: "age_groups",
        label: "Age Group",
        type: FormInputTypes.LOOKUP_MULTI_SELECT_REACT,
        lookup: "LK_AGE_GROUPS",
        isTextArray: true,
        format: (value) => value && value.map((v) => v.label),
        className:"parent-form-input"
      },
    ],
  },
  // {
  //   name: "phone",
  //   label: "Cell Phone",
  //   placeholder: "(212) 555-1234",
  //   type: FormInputTypes.PHONE,
  //   validator: requiredValidator,
  // },
];

export const PARENT_PLAYERS_HEADERS = [
  {
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    maxWidth: 50,
  },
  {
    field: "last_name",
    headerName: "Last Name",
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "first_name",
    headerName: "First Name",
    sortable: true,
    filter: "agTextColumnFilter",
  },

  {
    field: "house_hold",
    headerName: "House Hold",
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "address",
    headerName: "Address",
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "team.value",
    headerName: "Team",
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "team.season",
    headerName: "Season",
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "organization.value",
    headerName: "Organization",
    sortable: true,
    filter: "agTextColumnFilter",
  },
];
