import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {useForm} from "react-hook-form";
import applicationComander from "../../../store/commanders/application.comander";
import { useSelector } from "react-redux";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";

import {
  Box,
  Button,
  Group,
  PasswordInput,
  Space,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { validateEmail } from "../../../helpers/form.validators";
import { URL_PATHS } from "../../../consts/url.paths.consts";
import { Icon } from "@fortawesome/fontawesome-svg-core";
import LoaderComponent from "../../../components/loader/loader.cmp";
// import LoginMessageCmp from "./loginMessage.cmp";
import { useDisclosure } from "@mantine/hooks";
import "./login.page.css";
// import LoginRightMessage from "./login.right.message";

export default function LoginPage() {
  const appState = useSelector((state: any) => state.application);
  const existingUser = useSelector((state: any) => state.account.userData);

  const onSubmit = (data) =>
    applicationComander.cmd_login(data.email.toLowerCase(), data.password);

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },

    validate: {
      email: validateEmail,
    },
  });

  useEffect(() => {
    if (existingUser && existingUser.username) {
      form.setValues({ email: existingUser.email });
    }
  }, [existingUser?.username]);

  if (!appState.authorise) {
    // if (!appState.loading && !process.env.REACT_APP_LOCAL) {
    //   window.location.href = process.env.REACT_APP_AUTH_URL;
    //   return <LoaderComponent loading overlayLoading={false}></LoaderComponent>;
    // }
    return (
      <div>
        <div className="login-container-box">
          {/* <LoginMessageCmp /> */}
          <Box
            sx={{ maxWidth: 450, height: 542 }}
            mx="auto"
            className="auth-container login-sub-container"
          >
            <FontAwesomeIcon
              icon={faCircleUser as Icon}
              size={"5x"}
              className="auth-container--icon"
            />
            <Space h="xl" />
            <form
              onSubmit={form.onSubmit((values) => onSubmit(values))}
              className="u-w-100"
            >
              <TextInput
                required
                label="Email"
                placeholder="your@email.com"
                className="u-w-100"
                {...form.getInputProps("email")}
              />
              <Space h="md" />
              <PasswordInput
                required
                label="Password"
                placeholder="password"
                className="u-w-100"
                {...form.getInputProps("password")}
              />
              <Space h="xs" />
              <div>
                <Text
                  size="sm"
                  align="right"
                  className="auth-container--link"
                  onClick={() =>
                    applicationComander.cmd_redirect({
                      to: URL_PATHS.FORGOT_PASSWORD,
                      state: { email: form.values.email },
                    })
                  }
                >
                  Forgot Password
                </Text>
                <Text
                  size="sm"
                  className="auth-container--link auth-container--link-darker"
                  onClick={() =>
                    applicationComander.cmd_redirect({ to: URL_PATHS.SIGNUP })
                  }
                >
                  Not Registered yet?{" "}
                  <span className="u-color-primary">Sign Up</span>
                </Text>
              </div>
              <Group mt="lg" mb="sm">
                <Button size="lg" type="submit" className="u-w-100">
                  Login
                </Button>
                <Space h={"sm"}></Space>
              </Group>
            </form>
          </Box>
          {/* <LoginRightMessage /> */}
        </div>
      </div>
    );
  } else {
    return <Redirect to={URL_PATHS.APP} />;
  }
}
