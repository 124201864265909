import { PLATFORM_HOST } from "../../settings/setting";

export const submitAskForHelp = async (data) => {
  try {
    await fetch(`${PLATFORM_HOST}/api/askForHelp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return true;
  } catch (error) {
    throw error;
  }
};
