import { Auth } from "aws-amplify";
import { hideProgress, showProgress } from "../eventAction/progress.service";

export const mapUserInfo = (userInfo) => {
  if (!userInfo) return null;
  return {
    userName: userInfo.username,
    email: userInfo.attributes.email,
    phoneNumber: userInfo.attributes.phone_number,
    emailVerified: userInfo.attributes.email_verified,
    id_parent: userInfo.attributes["custom:id_parent"],
    id_organization: userInfo.attributes["custom:id_organization"],
    id_coupon: userInfo.attributes["custom:coupon"],
    id_sales: userInfo.attributes["custom:id_sales"],
  };
};

export async function getToken() {
  try {
    const currentToken: any = await Auth.currentSession();
    return currentToken.idToken.jwtToken;
  } catch (error) {
    throw error;
  }
}

export async function autoLogIn() {
  try {
    showProgress();
    const currentUserInfo = await Auth.currentAuthenticatedUser();
    return mapUserInfo(currentUserInfo);
  } catch (error) {
    throw error;
  } finally {
    hideProgress();
  }
}

export async function signUp(username: string, password: string, customAttr) {
  try {
    showProgress();
    const { user } = await Auth.signUp({
      username,
      password,
      attributes: {
        email: username, // optional
        // other custom attributes
        "custom:id_organization": customAttr.id_organization,
        "custom:id_parent": customAttr.id_parent,
        "custom:coupon": customAttr.coupon,
        "custom:id_sales": customAttr.id_sales,
      },
    });
    console.log(user);
  } catch (error) {
    throw error;
  } finally {
    hideProgress();
  }
}

export async function signIn(username: string, password: string) {
  try {
    showProgress();
    const user = await Auth.signIn(username, password);
    return user;
  } catch (error) {
    throw error;
  } finally {
    hideProgress();
  }
}

export async function verification(userName, activationCode: string) {
  try {
    const result = await Auth.confirmSignUp(userName, activationCode);
    return result;
  } catch (error) {
    console.log("error signing up:", error);
    throw Error(error);
  }
}

export async function resend_verification(username: string) {
  try {
    const result = await Auth.resendSignUp(username);
    return result;
  } catch (error) {
    console.log("error signing up:", error);
    throw error;
  }
}

export async function signOut() {
  try {
    const result = await Auth.signOut();
    return result;
  } catch (error) {
    console.log("error signing up:", error);
    throw error;
  }
}

export async function forgotPassword(userName: string) {
  try {
    const result = await Auth.forgotPassword(userName);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function forgotPasswordSubmit(
  userName: string,
  resetCode: string,
  newPassword: string
) {
  try {
    const result = await Auth.forgotPasswordSubmit(
      userName,
      resetCode,
      newPassword
    );
    return result;
  } catch (error) {
    console.log("error signing up:", error);
    throw error;
  }
}

export async function changePassword(oldPassword: string, newPassword: string) {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const response = await Auth.changePassword(user, oldPassword, oldPassword);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function newPassword(newPassword: string, userSession: any) {
  try {
    const result = await Auth.completeNewPassword(userSession, newPassword);
    return result;
  } catch (error) {
    throw error;
  }
}