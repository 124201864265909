import { HOST } from "../../settings/setting";
import { authFecth } from "../../helpers/authFecth";
import { toast } from "react-toastify";
import { progressAction } from "../helper/action.helper";
import { ac_get_account } from "./account.action";

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const ac_proccess_payment =
  (stripe, card, coupon_key, discount) =>
  async (dispatch: any, getState: any) => {
    progressAction(async () => {
      try {
        const state = getState();

        const user = state.application.user;
        const metadata = {
          email: user.email,
          coupon_key: coupon_key,
          discount: discount,
          id_sales: user.id_sales,
          id_organization: user.id_organization,
        };
        const paymentIntent: any = await authFecth(
          `${HOST}/api/stripe/intent`,
          "post",
          metadata
        );

        const result = await stripe.confirmCardPayment(
          paymentIntent.client_secret,
          {
            payment_method: {
              card: card,
              billing_details: {
                email: user.email,
              },
              metadata: metadata,
            },
          }
        );
        if ((result.paymentIntent.status = "succeeded")) {
          await timeout(2500);
          toast.success("Your Payment has been process successfully");
          dispatch(ac_get_account());
        } else {
          throw "Something went wrong with your payment";
        }
      } catch (error) {
        toast.error("Invalid card details");
      }
    });
  };
