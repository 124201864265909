import { toast } from "react-toastify";
import { authFecth } from "../../helpers/authFecth";
import { HOST } from "../../settings/setting";
import Const from "../consts";
import { progressAction } from "../helper/action.helper";
import axios from "axios";

export const ac_get_account = () => async (dispatch: any, getState: any) => {
  progressAction(async () => {
    try {
      dispatch(ac_app_loading(true));
      const response: any = await authFecth(`${HOST}/api/account`, "get", null);
      response.birth_date = new Date(response.birth_date);
      dispatch({
        type: Const.GET_ACCOUNT,
        payload: response,
      });
      if (response.enrolled) {
        dispatch({
          type: Const.REDIRECT,
          payload: { to: "/course" },
        });
      }
      dispatch(ac_app_loading(false));
    } catch (e) {
      toast.error(e.message);
      dispatch(ac_app_loading(false));
    }
  });
};

export const ac_get_email =
  (id_parent) => async (dispatch: any, getState: any) => {
    progressAction(async () => {
      try {
        const response: any = await axios.post(`${HOST}/api/account/email`, {
          id_parent,
        });
        dispatch({
          type: Const.GET_EMAIL,
          payload: response.data,
        });
      } catch (e) {
        toast.error(e.message);
      }
    });
  };

export const ac_update_account =
  (accountData, callback) => async (dispatch: any, getState: any) => {
    try {
      const response: any = await authFecth(
        `${HOST}/api/account`,
        "post",
        accountData
      );
      dispatch({
        type: Const.UPDATE_ACCOUNT,
        payload: response,
      });

      if (response.enrolled) {
        dispatch({
          type: Const.REDIRECT,
          payload: { to: "/course" },
        });
      }
      if (callback) callback();
      toast.info("Your Information Has Been Saved");
    } catch (e) {
      toast.error(JSON.parse(e.message.errorMessage).body);
    }
  };

export const ac_app_loading =
  (loading: boolean) => async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: Const.APP_LOADING,
        payload: loading,
      });
    } catch (e) {
      toast.error(e.message);
    }
  };

export const ac_submit_survey =
  (answers) => async (dispatch: any, getState: any) => {
    try {
      await authFecth(`${HOST}/api/feedback/feedbackForUser`, "post", {
        answers,
      });
      dispatch({
        type: Const.FEEDBACK_SUBMITTED,
        payload: {},
      });
      toast("Feedback submitted successfully");
    } catch (e) {
      toast.error("Error occurred while submitting your feedback");
      return null;
    }
  };

export const ac_toggle_notifications =
  () => async (dispatch: any, getState: any) => {
    try {
      const notifications_status =
        getState().account?.userData?.certificate_notifications_status;
      await authFecth(
        `${HOST}/api/notifications/unsubscribeUserFromNotification`,
        "post",
        { value: !notifications_status }
      );
      dispatch({
        type: Const.TOGGLE_NOTIFICATIONS,
        payload: !notifications_status,
      });
      toast(
        `Notifications have been turned ${notifications_status ? "on" : "off"}`
      );
    } catch (e) {
      return null;
    }
  };
