import { toast } from "react-toastify";
import { HOST } from "../settings/setting";
import { authFecth } from "./authFecth";
import ApplicationComander from "../store/commanders/application.comander";

export const GetSurveyForCourse = async ({ courseId }) => {
  try {
    const result: any = await authFecth(
      `${HOST}/api/feedback/feedbackForCourse`,
      "get"
    );
    return result;
  } catch (e) {
    toast.error("Error loading survey");
    return null;
  }
};
