import Actions from "../consts";

export const account = (state = { userData: {} }, action: any = {}) => {
  switch (action.type) {
    case Actions.GET_ACCOUNT:
      return { ...state, userData: action.payload };
    case Actions.GET_EMAIL:
      if (!action.payload) return { ...state };
      return { ...state, userData: action.payload };
    case Actions.UPDATE_ACCOUNT:
      return { ...state, userData: action.payload };
    case Actions.FEEDBACK_SUBMITTED:
      return {
        ...state,
        userData: { ...state.userData, feedback_submitted: true },
      };
    case Actions.TOGGLE_NOTIFICATIONS:
      return {
        ...state,
        userData: {
          ...state.userData,
          certificate_notifications_status: action.payload,
        },
      };
    default:
      return state;
  }
};
