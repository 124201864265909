import {buildStyles,CircularProgressbar} from "react-circular-progressbar";
import React from "react";

const CircularProgressCmp = ({grades,status, text}: {grades,status, text?})=>{
    const color ="green"
    return    <CircularProgressbar value={grades} text={text ? text :`${grades}%`}
                                   styles={buildStyles({
                                       pathColor:color,
                                       textColor: color,
                                   })}/>
};

export default CircularProgressCmp;