import { Button, Group, Modal, Space, Stack } from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import FormComponent, { FormInputTypes } from "../form/form.component";
import { emailValidator, requiredValidator } from "../form/validators.form";
import { toast } from "react-toastify";
import { loadLookups } from "../../helpers/lookup.helper";
import { submitAskForHelp } from "../../store/actions/form.actions";

const formSettings = [
  {
    name: "email",
    label: "Email",
    placeholder: "Email",
    type: FormInputTypes.TEXT,
    validator: emailValidator,
  },
  {
    name: "subject",
    label: "Subject",
    placeholder: "Subject",
    lookup: "LK_HELP_SUBJECTS",
    type: FormInputTypes.LOOKUP_SELECT,
    validator: requiredValidator,
  },
  {
    name: "message",
    label: "Message",
    placeholder: "Message",
    type: FormInputTypes.TEXTAREA,
    validator: requiredValidator,
  },
];

export const AskHelp = () => {
  const [opened, setOpened] = useState(false);

  const [lookups, setLookups] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const formRef = useRef(null);

  useEffect(() => {
    loadLookups(formSettings, {}, (data: Array<any>) => {
      setLookups(data);
    });
  }, []);

  const onsubmitHandler = async () => {
    setSubmitting(true);
    if (formRef.current) {
      const values = formRef.current.getFormValues();
      try {
        await submitAskForHelp(values);
        setOpened(false);
        toast.success("Your request has been submitted");
        setSubmitting(false);
      } catch (e) {
        console.error(e);
        toast.error("Failed to submit the form");
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Ask for help"
      >
        <Stack spacing="xl">
          <FormComponent
            config={formSettings}
            lookups={lookups}
            ref={formRef}
          />
          <Space h="md" />
          <Group position="right">
            <Button variant="outline" onClick={() => setOpened(false)}>
              Cancel
            </Button>
            <Button onClick={onsubmitHandler} loading={submitting}>
              Send
            </Button>
          </Group>
        </Stack>
      </Modal>

      <Button
        variant="filled"
        color="green"
        radius="xl"
        onClick={() => setOpened(true)}
      >
        {window.innerWidth <= 500 ? "Help?" : "Need Help?"}
      </Button>
    </>
  );
};
