import React from 'react'
import PropTypes from 'prop-types'
import './tree.component.css'
import Icons from './Icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareMinus, faSquarePlus } from "@fortawesome/free-regular-svg-icons"
import { Icon } from '@fortawesome/fontawesome-svg-core';

const styles = {
    tree: {
        position: 'relative',
        padding: '4px 0px 0px 4px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        // overflowX: 'hidden',
        verticalAlign: 'middle',
    },
    toggle: {
        width: '14',
        height: '14',
        marginRight: 4,
        cursor: 'pointer',
        verticalAlign: 'middle',
        fill: 'black'
    },
    type: {
        textTransform: 'uppercase',
        fontFamily: 'monospace',
        fontSize: '0.6em',
        verticalAlign: 'middle',
    },
    contents: {
        willChange: 'transform, opacity, height',
        marginLeft: 6,
        padding: '4px 0px 0px 14px',
        borderLeft: '1px dashed rgba(255,255,255,0.4)',
    },
}


export default class TreeComponent extends React.PureComponent<any, any> {
    static defaultProps = { open: false, visible: true, canHide: false }
    static propTypes = {
        open: PropTypes.bool,
        visible: PropTypes.bool,
        canHide: PropTypes.bool,
        content: PropTypes.node,
        springConfig: PropTypes.func,
    }

    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            visible: props.visible,
            dragHiglight: false,
            dragEnterCount: 0,
            intEditing: false,
            immediate: false,
            editContext: props.content
        }
    }

    expand = () => {
        if (this.props.children && !this.state.open) {
            this.setState({ open: true, immediate: true }, this.props.onClick);
        }
    }

    toggle = () => {
        this.props.children &&
            this.setState(state => ({ open: !state.open, immediate: true }))
        this.props.onClick && this.props.onClick();
    }

    onDoubleClick = () => {
        this.setState({ intEditing: true })
    }

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.setState({ intEditing: false })
            this.rename();

        }
        if (e.keyCode === 27) {
            this.setState({ intEditing: false, editContext: this.props.content })
        }
    }

    onBlur = (e) => {
        this.setState({ intEditing: false })
        this.rename();
    }

    onChangeContext = (e) => {
        const target = e.target;
        const value = target.value;
        this.setState({ editContext: value });
    }

    componentWillReceiveProps(props) {
        //if open let it open by the user we should not close it
        if (this.state.open)
            return;
        this.setState(state => {
            let result: any = ['open', 'visible'].reduce(
                (acc, val) =>
                    this.props[val] !== props[val] ? { ...acc, [val]: props[val] } : acc,
                {}
            )
            //reset state content if property change
            result.editContext = props.content;
            return result;
        })
    }

    rename() {
        if (this.props.onRename && this.state.editContext !== this.props.context)
            this.props.onRename(this.state.editContext)
    }

    onSelectItem = (e) => {
        e.stopPropagation();
        if (this.props.onSelect)
            this.props.onSelect(e)
    }

    allowDrop(e) {
        //Check file obj
        e.preventDefault();
        //change state
    }

    onDragEnter = (e) => {
        //This is needed to not affect the state of parent nodes
        e.stopPropagation();
        //The enter count debounce the internals leve and enters
        this.setState({
            dragHiglight: true,
            dragEnterCount: this.state.dragEnterCount < 0 ? 1 : this.state.dragEnterCount + 1
        });
    }
    onDragLeave = (e) => {
        //This is needed to not affect the state of parent nodesld
        e.stopPropagation();
        if (this.state.dragHiglight) {
            if (this.state.dragEnterCount === 1) {
                this.setState({ dragHiglight: false, dragEnterCount: 0 });
            } else {
                this.setState({ dragHiglight: true, dragEnterCount: this.state.dragEnterCount - 1 });
            }
        }

    }

    onDragStart = (event, item) => {

        event.dataTransfer.setData("itemDraggingId", item.id);
        event.stopPropagation()
    }

    render() {
        const { open, dragHiglight } = this.state
        const {
            children,
            item,
            renderItem,
            style,
            canDrag,
            canEdit,
            canSelect,
            dropable,
            dragDrop,
            completed
        } = this.props
        const Icon = Icons[`${children ? (open ? 'Minus' : 'Plus') : 'Close'}SquareO`]
        let contentStyle: any = this.props.selected && canSelect ? {
            verticalAlign: 'middle',
            backgroundColor: '#5697ff'
        } : { verticalAlign: 'middle' }
        contentStyle = dragHiglight ? { backgroundColor: '#907746' } : contentStyle;

        // if (!editing)
        return (
            <div style={{ ...styles.tree, ...style }} draggable={canDrag}
                onClick={(e) => this.onSelectItem(e)}
                onDoubleClick={canEdit ? (e) => this.onDoubleClick() : null}
                onDragOver={dropable ? this.allowDrop : null}
                onDragStart={canDrag ? (e) => this.onDragStart(e, item) : null}
                onDragEnter={dropable ? this.onDragEnter : null}
                onDragLeave={dropable ? this.onDragLeave : null}
                onDrop={dragDrop ? (e) => {
                    dragDrop(e, item)
                    this.setState({ dragHiglight: false, dragEnterCount: 0 });
                } : null}>


                <div className="treeview" style={contentStyle}>
                    {children ? null : completed ? <FontAwesomeIcon icon="check" size={"lg"} color="green" /> : null
                        // { item.status =='pass' ?
                        // <FontAwesomeIcon icon="check" size={"lg"} color="green"/>:null}:null

                    }

                    <FontAwesomeIcon icon={open ? faSquareMinus as Icon : faSquarePlus as Icon} onClick={this.toggle} style={{ ...styles.toggle, opacity: children ? 1 : 0 }} />
                    {renderItem ? renderItem(item) : null}
                </div>

                <div style={{ marginLeft: 8 }}>
                    {open ? children : null}
                </div>
            </div>
        )
    }
}
