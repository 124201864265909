import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import "./App.scss";
import { useSelector } from "react-redux";
import Enrollment from "./enrollment/enrollment";
import CoursePage from "./course/course.page";
import { AccountCommander } from "../store/commanders/account.commander";
import courseComander from "../store/commanders/course.comander";
import UserDiv from "../components/common/userdiv/UserDiv";
import Account from "./account/account";
import Coupon from "./coupon/coupon";
import PaymentPage from "./payment/payment";
import MapPage from "./map/map.page";

const App = () => {
  let location = useLocation();
  const user = useSelector((state: any) => state.application.user);
  let history = useHistory();

  const userData = useSelector((state: any) => state.account.userData);
  useEffect(() => {
    AccountCommander.cmd_get_account();
  }, []);

  useEffect(() => {
    if (
      userData &&
      Object.keys(userData).length &&
      userData.enrolled &&
      location.pathname !== "/account"
    ) {
      history.push(userData.valid_subscription ? "/course" : "/payment");
    }
  }, [userData.enrolled, location.pathname, userData.valid_subscription]);

  return (
    <div className="app-container">
      <div className="secondary-nav">
        {user.email}
        <UserDiv
          user={user}
          isAdmin={userData.is_admin}
          userData={userData}
          disabled={!userData.enrolled || !userData.valid_subscription}
        ></UserDiv>
      </div>
      <Switch>
        <Route path={`/admincoupons`} component={Coupon} />
        <Route path={`/account`} component={Account} />
        <Route path={`/map`} component={MapPage} />
        <Route path={`/course`} component={CoursePage} />
        <Route path={`/enrolment`} component={Enrollment} />
        <Route path={`/payment`} component={PaymentPage} />
        <Route component={Enrollment} />
      </Switch>
    </div>
  );
};

export default App;
