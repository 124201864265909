import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useImperativeHandle,
  useLayoutEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import classNames from "classnames";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./grid.style.scss";
import { Button, Group, Input, Space } from "@mantine/core";

const GridComponent = React.forwardRef(
  (
    {
      headers,
      rows,
      collectionName,
      masterCollection,
      onRowDBClick,
      className,
      onSelectionChange,
      quickFilter,
      singleSelect,
      showControls = true,
    }: {
      headers: Array<any>;
      rows: Array<any>;
      collectionName?: string;
      masterCollection?: boolean;
      onRowDBClick?: (row) => void;
      className?: string;
      onSelectionChange?: (row) => void;
      quickFilter?: boolean;
      singleSelect?: boolean;
      showControls?: boolean;
    },
    ref
  ) => {
    const gridRef = useRef();
    const [gridApi, setGridApi] = useState(null);
    const [columnApi, setColumnApi] = useState(null);

    useLayoutEffect(() => {
      if (!collectionName) return;
      if (gridApi) {
        const filterAndColumnState = JSON.parse(
          localStorage.getItem(
            masterCollection
              ? `${collectionName}_master`
              : `${collectionName}_detail`
          )
        );
        if (filterAndColumnState && filterAndColumnState.filterModel)
          gridApi.setFilterModel(filterAndColumnState.filterModel);

        if (filterAndColumnState && filterAndColumnState.columnState) {
          columnApi.applyColumnState({
            state: filterAndColumnState.columnState,
            applyOrder: true,
          });
        }
      }
    }, [collectionName, gridApi, columnApi]);

    const defaultColDef = useMemo(() => {
      return {
        flex: 1,
        minWidth: 100,
        resizable: true,
      };
    }, []);

    const selectedRows = () => {
      if (!gridRef.current) return [];
      //@ts-ignore
      return gridRef.current.api.getSelectedRows();
    };

    useImperativeHandle(ref, () => ({
      getSelectedRows: () => {
        return selectedRows();
      },
      getSelectedRow: () => {
        const rows = selectedRows();
        return rows.length ? rows[0] : null;
      },
    }));

    const onSelectionChanged = useCallback(() => {
      //@ts-ignore
      const selectedRows = gridRef.current!.api.getSelectedRows();
      if (onSelectionChange) onSelectionChange(selectedRows);
    }, []);

    const onFilterTextBoxChanged = useCallback(() => {
      if (!quickFilter) return;
      //@ts-ignore
      gridRef.current!.api.setQuickFilter(
        (document.getElementById("quick-filter-text") as HTMLInputElement).value
      );
    }, []);

    const savingFilterAndColumnState = (e) => {
      if (!collectionName) return;
      const filterModel = gridApi.getFilterModel();
      const columnState = columnApi.getColumnState();
      localStorage.setItem(
        masterCollection
          ? `${collectionName}_master`
          : `${collectionName}_detail`,
        JSON.stringify({ filterModel, columnState })
      );
    };

    const onGridReady = (e) => {
      setGridApi(e.api);
      setColumnApi(e.columnApi);
    };

    const resetFilters = () => {
      gridApi.setFilterModel();
      const columnState = columnApi.getColumnState();
      columnApi.applyColumnState({
        state: columnState.map((item) => {
          item.sort = null;
          return item;
        }),
        applyOrder: true,
      });
    };

    return (
      <div style={{ width: "100%" }}>
        <Group position="apart">
          {quickFilter ? (
            <Input
              placeholder="Search Players..."
              id="quick-filter-text"
              onChange={onFilterTextBoxChanged}
            ></Input>
          ) : (
            <div></div>
          )}
          {showControls ? (
            <Button variant="subtle" onClick={resetFilters}>
              Clear Filters
            </Button>
          ) : null}
        </Group>
        <Space h={"md"}></Space>

        <div
          className={classNames({
            "ag-theme-alpine": true,
            "grid-layout": true,
            [className]: className,
          })}
        >
          <div style={{ height: "100%" }}>
            <AgGridReact
              rowData={rows}
              columnDefs={headers}
              defaultColDef={defaultColDef}
              rowSelection={singleSelect ? "single" : "multiple"}
              onRowDoubleClicked={onRowDBClick}
              onSelectionChanged={onSelectionChanged}
              ref={gridRef}
              onFilterChanged={savingFilterAndColumnState}
              onSortChanged={savingFilterAndColumnState}
              onColumnMoved={savingFilterAndColumnState}
              onColumnResized={savingFilterAndColumnState}
              onGridReady={onGridReady}
            ></AgGridReact>
          </div>
        </div>
      </div>
    );
  }
);

export default GridComponent;
