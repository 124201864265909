/* @ts-ignore */
import store from "../store";
import { ac_proccess_payment } from "../actions/subscription.action"

const SubscriptionComander = {
    cmd_proccess_payment: (stripe, card, coupon_key, discount) => {
        // @ts-ignore
        store.dispatch(ac_proccess_payment(stripe, card, coupon_key, discount));
    }
};
export default SubscriptionComander;
