import { HOST } from "../../settings/setting";
import { authFecth } from "../../helpers/authFecth";
import { toast } from "react-toastify";
import { progressAction } from "../helper/action.helper";
import Const from "../consts";

export const ac_get_course = () => async (dispatch: any, getState: any) => {
  progressAction(async () => {
    try {
      const response: any = await authFecth(
        `${HOST}/api/course/getUserCourseSession`,
        "get",
        null
      );

      toast("Retrieving Course Data");
      dispatch({
        type: Const.GET_COURSE,
        payload: response,
      });
    } catch (e) {
      toast.error(e.message);
    }
  });
};

export const ac_select_section =
  (selected) => async (dispatch: any, getState: any) => {
    dispatch({
      type: Const.SET_COURSE_SECTION,
      payload: selected,
    });
  };

export const ac_mark_finish =
  (node) => async (dispatch: any, getState: any) => {
    dispatch({
      type: Const.MARK_LESSON_FINISH,
      payload: node,
    });
  };

export const ac_redirect_to_last_seen =
  () => async (dispatch: any, getState: any) => {
    dispatch({
      type: Const.GO_LAST_SEEN,
    });
  };

export const ac_next_lesson = () => async (dispatch: any, getState: any) => {
  dispatch({
    type: Const.NEXT_LESSON,
  });
};

export const ac_submit_quiz =
  (userAnswers: Array<any>) => async (dispatch: any, getState: any) => {
    const selectedQuiz = getState().course.selectedSection;
    const body = { answers: userAnswers, id: selectedQuiz.id };
    progressAction(async () => {
      try {
        const response: any = await authFecth(
          `${HOST}/api/course/submitQuiz`,
          "post",
          body
        );
        dispatch({
          type: Const.SUBMIT_QUIZ,
          payload: response,
        });

        if (response.status === "pass") {
          dispatch({
            type: Const.ASK_FEEDBACK,
            payload: {},
          });
        }
      } catch (e) {
        toast.error(e.message);
      }
    });
  };

export const ac_admin_pass_quiz =
  () => async (dispatch: any, getState: any) => {
    const selectedQuiz = getState().course.selectedSection;
    const body = { id: selectedQuiz.id };
    progressAction(async () => {
      try {
        const response: any = await authFecth(
          `${HOST}/api/course/adminPassQuizz`,
          "post",
          body
        );
        dispatch({
          type: Const.SUBMIT_QUIZ,
          payload: response,
        });
      } catch (e) {
        toast.error(e.message);
      }
    });
  };

export const ac_update_lastSeen =
  (itemId) => async (dispatch: any, getState: any) => {
    try {
      const response: any = await authFecth(
        `${HOST}/api/course/updateCourseSession`,
        "put",
        { id: itemId }
      );
      dispatch({
        type: Const.UPDATE_LAST_SEEN,
        payload: response,
      });
    } catch (e) {
      toast.error(e.message);
    }
  };

export const ac_retry_quiz =
  (selected) => async (dispatch: any, getState: any) => {
    dispatch({
      type: Const.RETRY_QUIZ,
      payload: selected,
    });
  };

export const ac_get_certificate =
  () => async (dispatch: any, getState: any) => {
    try {
      const response: any = await authFecth(
        `${HOST}/api/course/exportCertificate`,
        "get"
      );
    } catch (e) {
      toast.error(e.message);
    }
  };

export const ac_get_answers = async (quizzId: string): Promise<any> => {
  const body = { id: quizzId };
  try {
    return await authFecth(`${HOST}/api/course/getAnswers`, "post", body);
  } catch (e) {
    toast.error(e.message);
  }
};

export const ac_archive_session =
  () => async (dispatch: any, getState: any) => {
    progressAction(async () => {
      try {
        const newCourseSession = await authFecth(
          `${HOST}/api/course/archiveUserSessions`,
          "post"
        );
        toast("Switching course session");
        dispatch({
          type: Const.GET_COURSE,
          payload: newCourseSession,
        });
      } catch (e) {
        toast.error(e.message);
      }
    });
  };

export const ac_get_certificates =
  () => async (dispatch: any, getState: any) => {
    progressAction(async () => {
      try {
        const userCertificates = await authFecth(
          `${HOST}/api/course/getUserCertificates`,
          "get"
        );
        dispatch({
          type: Const.GET_CERTIFICATES,
          payload: userCertificates,
        });
      } catch (e) {
        toast.error(e.message);
      }
    });
  };
