import React from "react";
import Avatar from 'react-avatar';
import "./UserDiv.css";
import DropdownMenuHOC from "../dropdown-menu/dropdown.menu.component";
import applicationComander from "../../../store/commanders/application.comander";
import { withRouter } from "react-router-dom";
import { AccountCommander } from "../../../store/commanders/account.commander";

const dropDownOptions = [
    { title: 'Account Setting', icon: 'user-cog', navigateTo: "/account", requireSubscription: true },
    // {title: 'Coaches Registry', icon: 'magnifying-glass', navigateTo: "https://search.coachsafely.com",out:true},
    // { title: (props) => props.userData && props.userData?.certificate_notifications_status ? 'Turn on notifications' : 'Turn off notifications', icon: (props) => props.userData && props.userData?.certificate_notifications_status ? 'bell' : 'bell-slash', onClick: AccountCommander.cmd_toggle_notifications },
    { title: 'Sign Out', icon: 'sign-out-alt', onClick: applicationComander.cmd_signOut }
]

const UserDiv = ({ user, isAdmin, userData, disabled }) => {
    return (
        <div className="userDiv-Main-Container">
            {/*//@ts-ignore */}
            <Avatar name={user?.email} size="40" round="20px"/>
        </div>
    );
}

export default withRouter(DropdownMenuHOC(UserDiv, dropDownOptions)({ propsKey: 'user' }));
