const InitialState = {
    application: {
        authorise: false,
        user: null,
        redirectTo: null
    },
    course: {
        data: null,
        selectedSection: null,
    },
    account: {
        userData: {},
    },
    coupon: {
        data: [],
    }
}
export default InitialState;
