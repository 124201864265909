import React, {useEffect, useState} from 'react';
import {GetSurveyForCourse} from "../../../helpers/survey";
import {useForm} from "react-hook-form";
import FreeTextCmp from "./survey-components/free-text.cmp";
import BoolSurveyCmp from "./survey-components/bool.survey.cmp";
import SingleChoiceCmp from "./survey-components/single-choice.cmp";
import './course.survey.cmp.scss'
import MultiChoiceCmp from "./survey-components/multi-choice.cmp";

const QUESTION_TYPES = {
    FREE_TEXT: 'FREE_TEXT',
    BOOLEAN: 'BOOLEAN',
    SINGLE_CHOICE: 'SINGLE_CHOICE',
    MULTI_CHOICE: 'MULTI_CHOICE'
}


const SurveyQuestionAnswersRenderer = ({question, formRegister, error}) => {
    switch (question.question_type) {
        case QUESTION_TYPES.FREE_TEXT:
            return (
                <FreeTextCmp formRegister={formRegister} error={error}/>
            )
            break;
        case QUESTION_TYPES.BOOLEAN:
            return (
                <BoolSurveyCmp formRegister={formRegister} error={error}/>
            )
            break;
        case QUESTION_TYPES.SINGLE_CHOICE:
            return (
                <SingleChoiceCmp formRegister={formRegister} possibleAnswers={question.possible_answers} error={error}/>
            )
            break;
        case QUESTION_TYPES.MULTI_CHOICE:
            return (
                <MultiChoiceCmp formRegister={formRegister} possibleAnswers={question.possible_answers} error={error}/>
            )
            break;
        default:
            return null;
    }

}

const CourseSurveyCmp = ({courseId, innerRef, afterSubmitting, submitFeedback}) => {
    const [survey, setSurvey] = useState([])
    const [loadingSurvey, setLoadingSurvey] = useState(false)
    useEffect(() => {
        const fetchSurvey = async () => {
            setLoadingSurvey(true)
            const result: Array<any> = await GetSurveyForCourse(courseId)
            setSurvey(result);
            setLoadingSurvey(false)
        };

        fetchSurvey();

    }, [])

    const {register, handleSubmit, formState: {errors}} = useForm();
    const onSubmit = async (data) => {
        const answers = []
        for (const question in data) {
            answers.push({id_feedback: survey[0].feedback_id, id_feedback_question: question, answer: data[question]})
        }
        await submitFeedback(answers);
        if (afterSubmitting) afterSubmitting()
    }

    return (
        <>
            <div>
                   <span className="mb-3 d-block">
                Please complete this short survey that will be used to improve the design of this course for future
            students.
            </span>

                <span
                    className="mb-3 d-block"> Your responses will be anonymous, so please provide constructive comments.</span>
                <span className="mb-3 d-block">
                Thank you for your time!
            </span>
            </div>

            {loadingSurvey ? (
                <div>
                    <div className="loader loader-center"></div>
                </div>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)} ref={innerRef} className="course-survey">
                    {survey && survey.map((question, index) => {
                        return (
                            <div key={question.question_id}>
                                <div className="question">{index+1}- {question.question}</div>
                                <SurveyQuestionAnswersRenderer question={question}
                                                               formRegister={{...register(question.question_id, {required: true})}}
                                error={errors[question.question_id]}/>
                            </div>
                        )
                    })}
                </form>
            )}


        </>

    );
};

export default CourseSurveyCmp;