import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./course.page.scss";
import CourseComponent from "../../components/course/course.cmp";
import applicationComander from "../../store/commanders/application.comander";
import { FetchTranscripts } from "../../helpers/fetch.transcripts";
import { AccountCommander } from "../../store/commanders/account.commander";
import courseComander from "../../store/commanders/course.comander";

const CoursePage = (props) => {
  const userData = useSelector((state: any) => {
    return state.account.userData;
  });

  const course = useSelector((state: any) => state.course);
  const [showCongratsModel, setShowCongratsModel] = useState(false);

  const userName = userData.first_name + " " + userData.last_name;

  useEffect(() => {
    if (!course || !course.data) {
      courseComander.cmd_get_course();
    }
  }, [course?.id]);

  useEffect(() => {
    if (
      userData &&
      Object.keys(userData).length &&
      (!userData.enrolled || !userData.valid_subscription)
    ) {
      applicationComander.cmd_redirect({ to: "enrolment" });
    }
  }, []);

  useEffect(() => {
    if (course && course.selectedSection) {
      courseComander.cmd_get_certificates();
    }
  }, [course?.data]);

  useEffect(() => {
    if (
      userData &&
      Object.keys(userData).length &&
      !userData.feedback_submitted &&
      course &&
      course.data &&
      course.data.finish
    ) {
      setShowCongratsModel(true);
    } else {
      setShowCongratsModel(false);
    }
  }, [userData, course.data]);

  const recertifyAction = () => {
    courseComander.cmd_archive_session();
  };

  const submitFeedback = (answers) =>
    AccountCommander.cmd_submit_survey(answers);

  return (
    <CourseComponent
      course={course ? course.data : null}
      selected={course ? course.selectedSection : null}
      userCertificates={course ? course.certificates : null}
      userName={userName}
      state={userData.state}
      isUserAdmin={userData.is_admin}
      getTranscripts={() => FetchTranscripts(course.selectedSection)}
      showFeedbackForStudent={!userData.feedback_submitted}
      showCongrats={showCongratsModel}
      submitFeedback={submitFeedback}
      recertifyAction={recertifyAction}
    />
  );
};
export default CoursePage;
