/* @ts-ignore */
import {
    ac_get_course,
    ac_mark_finish,
    ac_redirect_to_last_seen,
    ac_select_section,
    ac_next_lesson,
    ac_submit_quiz,
    ac_update_lastSeen,
    ac_retry_quiz,
    ac_get_certificate, ac_admin_pass_quiz, ac_archive_session, ac_get_certificates
} from "../actions/course.action"
import store from "../../store/store";


const CourseComander = {
    cmd_get_course: () => {
        // @ts-ignore
        store.dispatch(ac_get_course());
    },
    cmd_select_section: (selected: any) => {
        // @ts-ignore
        store.dispatch(ac_select_section(selected));
    },
    cmd_mark_lesson_finish: (lesson: any) => {
        // @ts-ignore
        store.dispatch(ac_mark_finish(lesson))
    },
    ac_redirect_to_last_seen:()=> {
        // @ts-ignore
        store.dispatch(ac_redirect_to_last_seen())
    },
    ac_next_lesson:()=>{
        // @ts-ignore
        store.dispatch(ac_next_lesson())
    },
    ac_submit_quiz:(userAnswers)=>{
        // @ts-ignore
        store.dispatch(ac_submit_quiz(userAnswers))
    },
    cmd_update_lastSeen:(itemId)=>{
        // @ts-ignore
        store.dispatch(ac_update_lastSeen(itemId))
    },
    cmd_retry_quiz:(selected)=>{
        // @ts-ignore
        store.dispatch(ac_retry_quiz(selected))
    },
    cmd_get_certificate:()=>{
        // @ts-ignore
        store.dispatch(ac_get_certificate())
    },
    cmd_admin_pass_quiz:()=>{
        // @ts-ignore
        store.dispatch(ac_admin_pass_quiz())
    },
    cmd_archive_session:() => {
        // @ts-ignore
        store.dispatch(ac_archive_session())
    },
    cmd_get_certificates: () => {
        // @ts-ignore
        store.dispatch(ac_get_certificates())
    }


};
export default CourseComander;
