import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import applicationComander from "../../../store/commanders/application.comander";
import {
  Button,
  Container,
  Group,
  PasswordInput,
  Space,
  Text,
  TextInput,
  Title,
  Box,
  Checkbox,
  Anchor,
  List,
} from "@mantine/core";

import { useForm } from "@mantine/form";
import { validateEmail } from "../../../helpers/form.validators";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { URL_PATHS } from "../../../consts/url.paths.consts";
import { Icon } from "@fortawesome/fontawesome-svg-core";
import { AccountCommander } from "../../../store/commanders/account.commander";
import ApplicationComander from "../../../store/commanders/application.comander";

export default function SignupPage() {
  const appState = useSelector((state: any) => state.application);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const id_organization = searchParams.get("orgId");
  const id_parent = searchParams.get("id");
  const coupon = searchParams.get("coupon");
  const id_sales = searchParams.get("id_sales");

  useEffect(() => {
    AccountCommander.cmd_get_email(id_parent);
  }, [id_parent]);

  const userData = useSelector((state: any) => state.account.userData);

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },

    validate: {
      email: validateEmail,
      confirmPassword: (value, values) =>
        value !== values.password ? "Passwords did not match" : null,
    },
  });

  useEffect(() => {
    if (userData.username) {
      ApplicationComander.cmd_redirect({
        to: "/login",
      });
    }
    if (userData.email) {
      form.setValues({ email: userData.email });
    }
  }, [userData?.email]);

  const onSubmit = (data) =>
    applicationComander.cmd_signup(data.email.toLowerCase(), data.password, {
      id_organization,
      id_parent,
      coupon,
      id_sales,
    });

  if (appState.authorise) {
    return <Redirect to={URL_PATHS.APP} />;
  }

  return (
    <Box sx={{ maxWidth: 450 }} mx="auto" className="auth-container">
      <Title order={1} align={"center"}>
        Sign up to enter the course{" "}
      </Title>
      <Space h={"xl"}></Space>
      <Space h={"md"}></Space>
      <form
        onSubmit={form.onSubmit((values) => onSubmit(values))}
        className="u-w-100"
      >
        <TextInput
          mt="sm"
          required
          label="Email"
          placeholder="your@email.com"
          {...form.getInputProps("email")}
          disabled={id_parent != null}
        />

        <List size="xs" mt={"lg"} px={"md"} spacing={-2}>
          Password Rules
          <List.Item>Minimum 8 characters</List.Item>
          <List.Item>An uppercase letter</List.Item>
          <List.Item>A lowercase letter</List.Item>
          <List.Item>A number</List.Item>
        </List>

        <PasswordInput
          mt="sm"
          label="Password"
          placeholder="Password"
          {...form.getInputProps("password")}
        />

        <PasswordInput
          mt="sm"
          label="Confirm password"
          placeholder="Confirm password"
          {...form.getInputProps("confirmPassword")}
        />
        {/* <Checkbox
          mt="md"
          required
          label={
            <>
              Accepts{" "}
              <Anchor
                size="sm"
                href="https://www.topcloudops.com/terms-and-conditions"
                target="_blank"
              >
                Term of services
              </Anchor>{" "}
              and{" "}
              <Anchor
                size="sm"
                href="https://www.topcloudops.com/privacy-policy"
                target="_blank"
              >
                Privacy policy
              </Anchor>
            </>
          }
          {...form.getInputProps("termsOfService", { type: "checkbox" })}
        ></Checkbox> */}

        <Group position="right" mt="md">
          <Button size="lg" type="submit" className="u-w-100">
            Join
          </Button>
        </Group>
      </form>
      <Space h={"sm"}></Space>
      <Container className="u-w-100-p-0">
        <Text
          size="sm"
          className="auth-container--link auth-container--link-darker"
          onClick={() =>
            applicationComander.cmd_redirect({ to: URL_PATHS.LOGIN })
          }
        >
          <FontAwesomeIcon icon={faLongArrowAltLeft as Icon} /> Back to login
        </Text>
      </Container>
    </Box>
  );
}
