import { FormInputTypes } from "../components/form/form.component";
import { srv_get_lookups } from "../services/lookups/lookups.service";

export const loadLookups = async (
  config: any,
  extra: any,
  callback: Function
) => {
  const lookups_collections = getLookups(config);
  await srv_get_lookups(lookups_collections, extra, callback);
};

const getLookups = (config: any) => {
  const lookups_collections = {};
  config.forEach((item) => {
    if (item.type === FormInputTypes.SPACE) return;
    if (item.group) {
      item.group.forEach((nestedItem) => {
        addLookup(nestedItem, lookups_collections);
      });
      return;
    }
    addLookup(item, lookups_collections);
  });
  return Object.keys(lookups_collections);
};

const addLookup = (item: any, lookups_collections: any) => {
  if (
    (item.type === FormInputTypes.LOOKUP_SELECT ||
      item.type === FormInputTypes.LOOKUP_MULTI_SELECT ||
      item.type === FormInputTypes.LOOKUP_MULTI_SELECT_REACT ||
      item.type === FormInputTypes.LOOKUP_SELECT_REACT) &&
    item.lookup &&
    item.lookup.startsWith("LK_")
  )
    lookups_collections[item.lookup] = item.lookup;
};
