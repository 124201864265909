import store from "../store";
import { ac_get_coupons, ac_create_coupon, ac_update_coupon, ac_delete_coupon, ac_validate_coupon, ac_redeem_coupon, ac_send_coupon } from "../actions/coupon.action";

export const CouponCommander = {
    cmd_get_coupons: (terms) => {
        // @ts-ignore
        store.dispatch(ac_get_coupons(terms));
    },
    cmd_create_coupon: (accountData) => {
        // @ts-ignore
        store.dispatch(ac_create_coupon(accountData));
    },
    cmd_update_coupon: (accountData) => {
        // @ts-ignore
        store.dispatch(ac_update_coupon(accountData));
    },
    cmd_delete_coupon: (accountData) => {
        // @ts-ignore
        store.dispatch(ac_delete_coupon(accountData));
    },
    cmd_validate_coupon: async (coupon_key: string) => {
        // @ts-ignore
        return await ac_validate_coupon(coupon_key);
    },
    cmd_redeem_coupon: async (data: any) => {
        // @ts-ignore
        store.dispatch(ac_redeem_coupon(data));
    },
    cmd_send_coupon: async (coupon: any) => {
        // @ts-ignore
        store.dispatch(ac_send_coupon(coupon));
    },

}