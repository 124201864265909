import React from "react";
import "./navbar.component.scss";
import { Grid, Text } from "@mantine/core";

import logo from "../../assets/logo.png";
import logoCoach from "../../assets/logo-coach.png";
import logoCoachSmall from "../../assets/logo-coach-small.png";
import bgImg from "../../assets/background.png";
import { AskHelp } from "../ask-help/ask-help.component";
const NavbarComponent = ({ user }: { user: any }) => {
  return (
    <div>
      <div className="co-nav">
        {/* <img
        alt="Rotation Icon"
        className="rotate-Logo"
        src={logo}
        draggable="false"
        height={40}
      /> */}

        <div className="bar-container">
          <div
            className="co-nav__brand"
            style={{ display: "flex", width: "100%" }}
          >
            <a
              href="https://playsafelysports.com/"
              className="co-nav__brand--text"
            >
              <img
                src={logo}
                alt="playsports logo"
                className="co-nav__brand--img"
              />
            </a>
            <div className="text-logo-middle">
              <Text
                className="text-nav-middle"
                size="sm"
                weight={700}
                color="white"
              >
                Proud Partner with
              </Text>
            </div>
            <a href="https://coachsafely.org/" className="co-nav__brand--text">
              <img
                src={window.innerWidth <= 500 ? logoCoachSmall : logoCoach}
                alt="coach logo"
                className="co-nav__brand--img"
              />
            </a>
            <div className="ask-help-container">
              <AskHelp />
            </div>
          </div>
        </div>
      </div>
      <img src={bgImg} alt="Certifications" className="bg-image" />
    </div>
  );
};

export default NavbarComponent;
