import AppReducer from "./reducer";
import IntialState from "./intial.state";

import {applyMiddleware, createStore} from "redux";
//import undoMiddleware from './middlewares/undo.middleware'
import thunk from "redux-thunk";


/**
 * Initialise the application  store and reducers
 */
export default createStore(
    AppReducer,
    IntialState,
    applyMiddleware(thunk)
);
