import React, { Component } from "react";
class BtnCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }
  btnClickedHandler() {
    //@ts-ignore
    this.props.clicked(this.props.data);
  }
  render() {
    return <button onClick={this.btnClickedHandler}>Send Email</button>;
  }
}
export default BtnCellRenderer;
