import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import App from "./pages/App";
import Login from "./pages/auth/login/login.page";
import SignUpPage from "./pages/auth/signup/signup.page";

import ProgressInfo from "./components/progressInfo/ProgressInfo";
import ActivateAccountPage from "./pages/auth/activate-account/activate.account.page";
import ResetPasswordPage from "./pages/auth/reset-password/reset.password.page";
import applicationComander from "./store/commanders/application.comander";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPasswordPage from "./pages/auth/forgot-password/forgot.password.page";
import NavbarComponent from "./components/navbar/navbar.component";
import ChangePasswordPage from "./pages/auth/new-password/new-password";
import LoaderComponent from "./components/loader/loader.cmp";
import ReactGA4 from "react-ga4";

const Main = () => {
  const appState = useSelector((state: any) => state.application);
  const user = useSelector((state: any) => state.application.user);
  useEffect(() => {
    ReactGA4.initialize("G-D9F7Y6V1GD");
    applicationComander.cmd_autoLogIn();
  }, []);

  const loading = useSelector((state: any) => state.application.loading);
  const overlayLoading = useSelector(
    (state: any) => state.application.overlayLoading
  );

  const isInitialising =
    // accountData === undefined ||
    loading || overlayLoading;

  return (
    <>
      <ToastContainer position="top-right" autoClose={1500} theme={"dark"} />

      {isInitialising ? (
        <LoaderComponent loading overlayLoading={false}></LoaderComponent>
      ) : null}
      <Router>
        <NavbarComponent user={user} />
        {appState.redirectTo ? (
          <Redirect
            to={{
              pathname: appState.redirectTo.to,
              state: appState.redirectTo,
            }}
          />
        ) : null}
        <div id="routeContainer">
          <div id="routeContainer">
            {/*//@ts-ignore */}
            <ProtectedRoute path="*" component={App} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={SignUpPage} />
            <Route
              path="/activate"
              render={(props) => <ActivateAccountPage {...props} />}
            />
            <Route
              path="/new-password"
              render={(props) => <ChangePasswordPage {...props} />}
            />

            <Route
              path="/forgot-password"
              render={(props) => <ForgotPasswordPage {...props} />}
            />
            <Route
              path="/reset-password"
              render={(props) => <ResetPasswordPage {...props} />}
            />
          </div>
        </div>
      </Router>

      <ProgressInfo />
    </>
  );
};

export default Main;
