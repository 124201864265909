export const PARENT_PLAYERS_HEADERS = [
  {
    field: "last_name",
    headerName: "Last Name",
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "first_name",
    headerName: "First Name",
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "address",
    headerName: "Address",
    sortable: true,
    filter: "agTextColumnFilter",
  }
];
