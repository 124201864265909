import React from 'react';

const MultiChoiceCmp =  ({formRegister, possibleAnswers, error}) => {
    return (
        <div>
            {possibleAnswers.map((answer, index) => (
                <div key={index} className="d-flex">
                    <input {...formRegister} type="checkbox" value={answer} id={`${formRegister.name}_${answer}`} className={error ? "error-text-radio quiz-radiobox" :"quiz-radiobox"}/>
                    <label htmlFor={`${formRegister.name}_${answer}`}>{answer}</label>
                </div>
            ))}

        </div>
    );
};

export default MultiChoiceCmp;