import {hideProgress, showProgress} from "../../services/eventAction/progress.service";

export const progressAction = async (fn: Function) => {
    try {
        showProgress();
        await fn();
    } finally {
        hideProgress()
    }
}
