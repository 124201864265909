import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Chips, { Chip } from "react-chips";
import StudentComander from "../../store/commanders/student.comander";
import { CouponCommander } from "../../store/commanders/coupon.commander";

const FormError = ({ message }) => {
  return <div style={{ color: "red" }}>{`* ${message}`}</div>;
};

const CouponFormCmp = ({ selected, innerRef, onSubmit, afterSubmitting }) => {
  const [selectedUser, setSelected] = useState([
    selected ? selected.user : null,
  ]);

  const {
    register: registerAll,
    handleSubmit: handleSubmitPersonal,
    formState: { errors: errorsPersonal },
    control,
    setValue,
    watch,
  } = useForm({ mode: "all" });

  const onSubmitCoupon = (data) => {
    if (!selected) {
      // data.user = selectedUser[1]
      CouponCommander.cmd_create_coupon(data);
    } else {
      data.id = selected.id;
      // data.user = selectedUser[0]
      CouponCommander.cmd_update_coupon(data);
    }

    afterSubmitting();
  };

  const generatekey = () => {
    var result = [];

    let strLength = 6;
    // const charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    while (strLength--) {
      // (note, fixed typo)
      result.push(charSet.charAt(Math.floor(Math.random() * charSet.length)));
    }

    return result.join("");
  };

  // const fetchSuggestion = (term: string) => {
  //     return StudentComander.cmd_search_student(term);
  // }
  // const renderSuggestion = (value) => {
  //     return <div>{`${value.first_name} ${value.last_name}`}</div>
  // }
  // const renderChip = (value) => {
  //     if (!value) return <></>;
  //     return <Chip>{`${value.first_name} ${value.last_name}`}</Chip>
  // }
  // const selectUserValidator = (value) => {
  //     return value != null;
  //     // const currentIndex = selectedUser.length - 1;
  //     // return selectedUser[currentIndex] && selectedUser[currentIndex].id ? true : false
  // }

  return (
    <form onSubmit={handleSubmitPersonal(onSubmitCoupon)} ref={innerRef}>
      <div className="col-md-4">
        <label>Coupon Key</label>
        <input
          className="ch-input"
          placeholder="Key Name"
          readOnly
          defaultValue={selected ? selected.coupon_key : generatekey()}
          {...registerAll("coupon_key", { required: true })}
        />
      </div>
      <div className="col-md-4">
        <label>Admin Email</label>
        {/* <Chips
                    {...registerAll("user", { required: false, validate: value => selectUserValidator(value) })}
                    value={selectedUser}
                    uniqueChips={true}
                    fromSuggestionsOnly={true}
                    onChange={(chip) => setSelected(chip)}
                    fetchSuggestions={fetchSuggestion}
                    renderSuggestion={renderSuggestion}
                    multiSection={false}
                    renderChip={renderChip}
                /> */}

        <input
          className="ch-input"
          defaultValue={selected?.coupon_admin_email}
          {...registerAll("user", {
            required: true,
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Please enter a valid email",
            },
          })}
        />
        {errorsPersonal.user?.message && (
          <FormError message={errorsPersonal.user?.message} />
        )}
      </div>
      <div className="col-md-4">
        <label>Discount %</label>
        <input
          className="ch-input"
          placeholder="0"
          type="number"
          defaultValue={selected?.coupon_discount}
          {...registerAll("coupon_discount", {
            required: true,
            max: {
              value: 100,
              message: "The discaount is a number between 1 - 100",
            },
          })}
        />
        {errorsPersonal.coupon_discount?.message && (
          <FormError message={errorsPersonal.coupon_discount?.message} />
        )}
      </div>
      <div className="col-md-4">
        <label>Units</label>
        <input
          className="ch-input"
          placeholder="0"
          type="number"
          min={1}
          defaultValue={selected?.units}
          {...registerAll("units", { required: true })}
        />
      </div>
    </form>
  );
};

export default CouponFormCmp;
