import React, { useState } from "react";

import "./course.cmp.scss";
import CourseTreeCmp from "./course.tree/course.tree.cmp";
import CoursePlayer from "./course.player/course.player.cmp";
import CourseComander from "../../store/commanders/course.comander";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "@fortawesome/fontawesome-svg-core";

const CourseComponent = ({
  course,
  selected,
  userName,
  state,
  isUserAdmin,
  getTranscripts,
  showFeedbackForStudent,
  showCongrats,
  submitFeedback,
  recertifyAction,
  userCertificates,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const [isCourseMenuOpen, setIsCourseMenuOpen] = useState(false);

  const onSelect = (item) => {
    setIsCourseMenuOpen(false);
    CourseComander.cmd_select_section(item);
  };

  return (
    <>
      <div className="course-cmp">
        {!isCourseMenuOpen && isTabletOrMobile ? (
          <div className="course-content-icon">
            <div onClick={() => setIsCourseMenuOpen(true)}>
              <FontAwesomeIcon icon={faChevronLeft as Icon} />
              Course Content
            </div>
          </div>
        ) : null}
        <div className="course-content">
          <CoursePlayer
            selected={selected}
            onSelect={onSelect}
            state={state}
            userName={userName}
            isUserAdmin={isUserAdmin}
            getTranscripts={getTranscripts}
            showFeedbackForStudent={showFeedbackForStudent}
            showCongrats={showCongrats}
            submitFeedback={submitFeedback}
            recertifyAction={recertifyAction}
            userCertificates={userCertificates}
          />
        </div>

        <div
          className={
            isTabletOrMobile ? "course-tree course-tree-mobile" : "course-tree"
          }
          hidden={!isCourseMenuOpen && isTabletOrMobile}
        >
          {isTabletOrMobile ? (
            <div
              className="cf-navbar__links--link d-inline-block"
              onClick={() => setIsCourseMenuOpen(false)}
            >
              <FontAwesomeIcon icon={faXmark as Icon} className="xmark-icon" />
            </div>
          ) : null}
          <CourseTreeCmp
            item={course}
            onSelect={onSelect}
            selected={selected}
          />
        </div>
      </div>
    </>
  );
};
export default CourseComponent;
