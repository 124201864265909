import React, { useEffect, useRef, useState } from "react";

import "./course.video.cmp.scss";
import ReactPlayer from "react-player";
import CourseComander from "../../../store/commanders/course.comander";
import courseComander from "../../../store/commanders/course.comander";
import { debounce } from 'lodash'

const CourseVideoCmp = ({ selected, getTranscripts }) => {
    const [isMarked, setIsMarked] = useState(selected.finish)
    const [currentTime, setCurrentTime] = useState(null)
    const [playing, setPlaying] = useState(true)
    const playerRef = useRef<any>(null);
    const transcriptRef = useRef<any>(null);
    const [loadingTranscripts, setLoadingTranscripts] = useState(false)

    const [transcripts, setTranscripts] = useState([])


    const onWordClick = (e, time) => {
        playerRef.current.seekTo(Number(time))
    }

    const ensureVisibility = () => {
        // @ts-ignore
        const selectedElement = Array.from(transcriptRef.current.childNodes).find(el => el.className === 'selected-word')
        if (selectedElement) {
            // @ts-ignore
            transcriptRef.current.scrollTop = selectedElement.offsetTop - (transcriptRef.current.offsetHeight - 20) > 0 ? selectedElement.offsetTop : 0
        }
    }

    const ensureVisibilityDebounced = debounce(ensureVisibility, 1000)

    useEffect(() => {
        setLoadingTranscripts(true)
        const fetchTranscript = async () => {
            const result = await getTranscripts(selected)
            setLoadingTranscripts(false)
            setTranscripts(result);
        };

        fetchTranscript();

    }, [selected.url])

    return (
        <>
            <div className="video-container">
                {/*//@ts-ignore */}
                <ReactPlayer url={selected.url} controls={false} playing={playing}
                    onProgress={(e) => {
                        setCurrentTime(e.playedSeconds)
                        ensureVisibilityDebounced()
                        if (e.played >= 0.9 && !isMarked) {
                            CourseComander.cmd_update_lastSeen(selected.id)
                            setIsMarked(true)
                        }
                    }
                    } ref={playerRef} progressInterval={100} /></div>
            <div className="module-button-container">
                <button className="cf-navbar__links--link cf-navbar__links--link-primary"
                    onClick={() => setPlaying(!playing)}>{playing ? "Pause" : "Play"}
                </button>

                <button className="cf-navbar__links--link cf-navbar__links--link-primary"
                    onClick={() => courseComander.ac_next_lesson()}>Next
                </button>
            </div>

            <div className="transcript">

                <div className="transcript__body" ref={transcriptRef}>

                    {loadingTranscripts ? (
                        <div>
                            <div className="loader loader-center"></div>
                        </div>
                    ) : transcripts.map((word, index) => {
                        return (
                            <span key={index}
                                className={word.start_time < currentTime && word.end_time > currentTime ? 'selected-word' : ''}
                                onDoubleClick={(e) => onWordClick(e, word.start_time)}
                                onTouchStart={(e) => onWordClick(e, word.start_time)}
                            ><span>{word.punct}</span> </span>
                        )
                    })}

                </div>

            </div>
        </>

    )
}
export default CourseVideoCmp;
