import { toast } from "react-toastify";
import { PLATFORM_HOST } from "../../settings/setting";
import { progressAction } from "../../store/helper/action.helper";

export const srv_get_lookups = async (
  collections: Array<string>,
  extra: any,
  callback: Function
) => {
  progressAction(async () => {
    try {
      const response: any = await fetch(`${PLATFORM_HOST}/api/global/lookups`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ collections, extra }),
      });
      const data = await response.json();
      callback(data);
    } catch (e) {
      toast.error(e.message);
    }
  });
};
