import {
  autoLogIn,
  changePassword,
  forgotPassword,
  forgotPasswordSubmit,
  resend_verification,
  signIn,
  signOut,
  signUp,
  verification,
  newPassword,
  mapUserInfo,
} from "../../services/auth/cognito.service";
import Const from "../consts";
import { toast } from "react-toastify";
import { WelcomeMessage } from "../helper/welcome.helper";
import { progressAction } from "../helper/action.helper";
import { ac_app_loading, ac_get_account } from "./account.action";

export const ac_login =
  (user: string, password: string) => async (dispatch: any, getState: any) => {
    try {
      const response: any = await signIn(user, password);

      if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
        toast.warn("Please enter a new password!");
        dispatch(
          ac_redirect({
            to: "/new-password",
          })
        );

        dispatch({
          type: Const.UN_AUTHORISE,
          payload: { tempSession: response },
        });

        return;
      }

      if (!response.attributes.email_verified) {
        await signOut();
        throw new Error("User is not confirmed.");
      }

      dispatch(ac_get_account());
      const userInfo = mapUserInfo(response);
      dispatch({
        type: Const.AUTHORISE,
        payload: userInfo,
      });

      toast.success(`${WelcomeMessage()} ${response.attributes.email}!`);
    } catch (e) {
      if (e.message.includes("User is not confirmed.")) {
        toast.warn("Please confirm your email!");
        resend_verification(user)
          .then((res) => {
            dispatch(
              ac_redirect({
                to: "/activate",
                userName: user,
                password: password,
              })
            );
          })
          .catch((e) => {
            toast.error(e.message);
          });

        return;
      }
      toast.error(e.message);
      dispatch({
        type: Const.UN_AUTHORISE,
        payload: {},
      });
    }
  };

export const ac_signup =
  (user, password, customAttr) => async (dispatch: any, getState: any) => {
    try {
      const response: any = await signUp(user, password, customAttr);
      dispatch(
        ac_redirect({
          to: "/activate",
          userName: user,
          password,
        })
      );
      return response;
    } catch (e) {
      toast.error(e.message);
    }
  };

export const ac_verify =
  (userName: string, activationCode: string, password?: string) =>
  async (dispatch: any, getState: any) => {
    try {
      const response: any = await verification(userName, activationCode);
      toast("The account has been verified successfully");

      if (password) {
        dispatch(ac_login(userName, password));
        dispatch(
          ac_redirect({
            to: "/",
          })
        );
      }
      return response;
    } catch (e) {
      toast.error(e.message);
    }
  };

export const ac_redirect = (link) => {
  return { type: Const.REDIRECT, payload: link };
};

export const ac_resend_code =
  (userName) => async (dispatch: any, getState: any) => {
    try {
      const response: any = await resend_verification(
        userName ? userName.toLowerCase() : userName
      );
      toast("Code has been resent to your email");
      return response;
    } catch (e) {
      toast.error(e.message);
    }
  };

export const ac_signOut = () => async (dispatch: any, getState: any) => {
  try {
    await signOut();
    window.location.href = process.env.REACT_APP_AUTH_URL;
    dispatch({
      type: Const.UN_AUTHORISE,
      payload: {},
    });
    dispatch({
      type: Const.APP_LOADING,
      payload: true,
    });
  } catch (e) {
    toast.error(e.message);
    dispatch({
      type: Const.APP_LOADING,
      payload: false,
    });
  }
};

export const ac_autoLogIn = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(ac_app_loading(true));
    const userInfo: any = await autoLogIn();
    dispatch(ac_get_account());
    dispatch({
      type: !userInfo ? Const.UN_AUTHORISE : Const.AUTHORISE,
      payload: userInfo,
    });
    dispatch(ac_app_loading(false));
    if (userInfo && userInfo.email) {
      toast.success(`${WelcomeMessage()} ${userInfo.email}!`);
    }
  } catch (e) {
    dispatch(ac_app_loading(false));
    toast.error(e.message);
  }
};

export const ac_forgot_password =
  (userName: string) => async (dispatch: any, getState: any) => {
    try {
      await forgotPassword(userName ? userName.toLowerCase() : userName);
      toast("Code has been sent to your email");
      dispatch(
        ac_redirect({
          to: "/reset-password",
          userName: userName,
        })
      );
    } catch (e) {
      toast.error(e.message);
    }
  };

export const ac_forgot_password_submit =
  (userName: string, resetCode: string, newPassword: string) =>
  async (dispatch: any, getState: any) => {
    try {
      const response: any = await forgotPasswordSubmit(
        userName,
        resetCode,
        newPassword
      );
      toast("Password changed successfully");
      if (response) {
        dispatch(ac_login(userName, newPassword));
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

export const ac_change_password =
  (oldPassword: string, newPassword: string) =>
  async (dispatch: any, getState: any) => {
    progressAction(async () => {
      try {
        const response: any = await changePassword(oldPassword, newPassword);
        toast("Password changed successfully");
        return response;
      } catch (e) {
        toast.error(e.message);
      }
    });
  };

export const ac_new_password =
  (password: string, userSession: any) =>
  async (dispatch: any, getState: any) => {
    progressAction(async () => {
      try {
        const response: any = await newPassword(password, userSession);
        toast("Password changed successfully");
        window.location.reload();
      } catch (e) {
        toast.error(e.message);
      }
    });
  };
