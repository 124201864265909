import EventObservable from "../observables/EventObservable";

export const EVENT_SHOW_PROGRESS = "EVENT_SHOW_PROGRESS"
export const EVENT_HIDE_PROGRESS = "EVENT_HIDE_PROGRESS"

export const showProgress = () => {
    EventObservable.sendEvent(EVENT_SHOW_PROGRESS)
}

export const hideProgress = () => {
    EventObservable.sendEvent(EVENT_HIDE_PROGRESS)
}
