import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import Amplify from "aws-amplify";
import { awsConfig } from "./settings/setting";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import Main from "./main";
import { Provider } from "react-redux";
import Store from "./store/store";
import "react-circular-progressbar/dist/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "./styles/main.scss";
import { MantineProvider } from "@mantine/core";

library.add(fas as any, faSignOutAlt as any);
Amplify.configure(awsConfig);

ReactDOM.render(
  <MantineProvider
    withGlobalStyles
    withNormalizeCSS
    theme={{
      colors: {
        brand: [
          "#eaf0fa",
          "#c0d2f1",
          "#96b5e8",
          "#6c97df",
          "#4279d6",
          "#2E308C", // primary
          "#173569",
          "#2455a8", // hover
          "#0e203f",
          "#050b15",
        ],
      },
      primaryColor: "brand",
    }}
  >
    <Provider store={Store}>
      <Main />
    </Provider>
  </MantineProvider>,
  document.getElementById("root")
);
reportWebVitals();
