import React, {useEffect, useState} from "react";

import "./course.module.cmp.scss";
import courseComander from "../../../store/commanders/course.comander";



const CourseModuleCmp = ({selected}) => {
    return <div className="marks-bg p-5">
        <div className="p-5">
            <div className="module-title">{selected.title}</div>
            <div className="module-description">{selected.description}</div>
            <div className="module-button-container">
                <button className="cf-navbar__links--link cf-navbar__links--link-primary" onClick={() => {
                    courseComander.ac_next_lesson()
                    courseComander.cmd_update_lastSeen(selected.id)
                }}>Next</button>
            </div>

        </div>
    </div>;
}
export default CourseModuleCmp;
