import React, { useEffect, useState } from "react";
import applicationComander from "../../../store/commanders/application.comander";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import {
  faCircleUser,
  faLongArrowAltLeft,
} from "@fortawesome/free-solid-svg-icons";

import {
  Box,
  Button,
  Group,
  List,
  Space,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { URL_PATHS } from "../../../consts/url.paths.consts";
import { Icon } from "@fortawesome/fontawesome-svg-core";
import { Redirect } from "react-router-dom";

const ChangePasswordPage = (props) => {
  const appState = useSelector((state: any) => state.application);

  const [activationCode, setActivationCode] = useState("");

  const tempSession = useSelector(
    (state: any) => state.application.tempSession
  );

  const onSubmit = (data) =>
    applicationComander.cmd_new_password(data.new_password, tempSession);

  const form = useForm({
    initialValues: {
      new_password: "",
    },
  });
  if (!appState.authorise) {
    return (
      <Box sx={{ maxWidth: 450 }} mx="auto" className="auth-container">
        <FontAwesomeIcon
          icon={faCircleUser as Icon}
          size={"5x"}
          className="auth-container--icon"
        />

        <Space h="xl" />
        <form
          onSubmit={form.onSubmit((values) => onSubmit(values))}
          className="u-w-100"
        >
          <Title order={4} align="center">
            Enter your new password
          </Title>
          <Space h="md" />
          <List size="xs" mt={"lg"} px={"md"} spacing={-2}>
            Password Rules
            <List.Item>Minimum 8 characters</List.Item>
            <List.Item>An uppercase letter</List.Item>
            <List.Item>A lowercase letter</List.Item>
            <List.Item>A number</List.Item>
          </List>

          <Space h="md" />
          <TextInput
            required
            label="New Password"
            placeholder=""
            className="u-w-100"
            {...form.getInputProps("new_password")}
            type="password"
          />

          <Group mt="lg" mb="sm">
            <Button size="lg" type="submit" className="u-w-100">
              Change Password
            </Button>
          </Group>

          <Text
            size="sm"
            className="auth-container--link auth-container--link-darker"
            onClick={() =>
              applicationComander.cmd_redirect({ to: URL_PATHS.LOGIN })
            }
          >
            <FontAwesomeIcon icon={faLongArrowAltLeft as Icon} /> Back to login
          </Text>
        </form>
      </Box>
    );
  } else {
    return <Redirect to={URL_PATHS.APP} />;
  }
};

export default ChangePasswordPage;
