import React, { useEffect } from "react";
import Logo from "../../../components/common/logo/Logo";
import {
  Button,
  Container,
  Group,
  PasswordInput,
  Space,
  Text,
  Box,
  TextInput,
  Title,
} from "@mantine/core";
import { useSelector } from "react-redux";
import { useForm } from "@mantine/form";
import applicationComander from "../../../store/commanders/application.comander";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { URL_PATHS } from "../../../consts/url.paths.consts";
import { validateEmail } from "../../../helpers/form.validators";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "@fortawesome/fontawesome-svg-core";
const ResetPasswordPage = (props) => {
  const onSubmit = (data) => {
    applicationComander.cmd_forgot_password_submit(
      data.userName,
      data.resetCode,
      data.confirmPassword
    );
  };

  const userName = useSelector((state: any) =>
    state.application.user ? state.application.user.userName : null
  );

  const form = useForm({
    initialValues: {
      userName: userName ? userName : "",
      password: "",
      confirmPassword: "",
      resetCode: "",
    },

    validate: {
      userName: validateEmail,
      confirmPassword: (value, values) =>
        value !== values.password ? "Passwords did not match" : null,
    },
  });
  return (
    <Box sx={{ maxWidth: 450 }} mx="auto" className="auth-container">
      <Title order={1} align={"center"}>
        Reset Password
      </Title>
      <Space h={"xl"}></Space>

      <form
        onSubmit={form.onSubmit((values) => onSubmit(values))}
        className="u-w-100"
      >
        <TextInput
          mt="sm"
          required
          label="User"
          placeholder="your@email.com"
          {...form.getInputProps("userName")}
        />
        <PasswordInput
          mt="sm"
          required
          label="Password"
          placeholder="Password"
          {...form.getInputProps("password")}
        />

        <PasswordInput
          mt="sm"
          required
          label="Confirm password"
          placeholder="Confirm password"
          {...form.getInputProps("confirmPassword")}
        />

        <TextInput
          mt="sm"
          required
          label="Reset code"
          placeholder=""
          {...form.getInputProps("resetCode")}
        />

        <Group position="right" mt="md">
          <Button size="lg" type="submit" className="u-w-100">
            Reset
          </Button>
        </Group>
      </form>
      <Space h="sm"></Space>
      <Container className="u-w-100-p-0">
        <Text
          size="sm"
          className="auth-container--link auth-container--link-darker"
          onClick={() =>
            applicationComander.cmd_redirect({ to: URL_PATHS.LOGIN })
          }
        >
          <FontAwesomeIcon icon={faLongArrowAltLeft as Icon} /> Back to login
        </Text> 
      </Container>
    </Box>
  );
};

export default ResetPasswordPage;
