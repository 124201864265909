import { Title, Space, Container } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import Account from "../../components/account/account";
import GridComponent from "../../components/grid/grid.component";
import { PARENT_PLAYERS_HEADERS } from "../../consts/grid.columns";
import { AccountCommander } from "../../store/commanders/account.commander";

export default function EnrollmentPage() {
  const userData = useSelector((state: any) => state.account.userData);
  const onEditHandler = (data) => {
    AccountCommander.cmd_update_account({
      ...userData,
      ...data,
      enrolled: true,
    });
  };

  return (
    <Container size={"xl"} className="u-w-100">
      <Account
        data={userData}
        onEditHandler={onEditHandler}
        label={"Proceed to course"}
      ></Account>
      <Space h={"xl"} />
      {userData.players_list &&
      userData.players_list.length &&
      userData.players_list[0].id ? (
        <>
          <Title order={2}>Players </Title>
          <GridComponent
            rows={userData.players_list}
            headers={PARENT_PLAYERS_HEADERS}
            showControls={false}
          ></GridComponent>
        </>
      ) : null}
    </Container>
  );
}
