import store from "../store";
import {ac_get_account, ac_update_account, ac_get_email} from "../actions/account.action";
import {ac_submit_survey, ac_toggle_notifications} from "../actions/account.action";

export const AccountCommander = {
    cmd_get_account: () => {
        // @ts-ignore
        store.dispatch(ac_get_account());
    },
    cmd_get_email: (id_parent) => {
        // @ts-ignore
        store.dispatch(ac_get_email(id_parent));
    },
    cmd_update_account: (accountData, callback?) => {
        // @ts-ignore
        store.dispatch(ac_update_account(accountData, callback));
    },
    cmd_submit_survey: (answers) => {
        // @ts-ignore
        store.dispatch(ac_submit_survey(answers))
    },
    cmd_toggle_notifications: () => {
        // @ts-ignore
        store.dispatch(ac_toggle_notifications())
    }
}
