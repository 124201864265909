import React from "react";
import CourseVideoCmp from "../course.video/course.video.cmp";
import CourseQuizCmp from "../course.quiz/course.quiz.cmp";
import "./course.player.cmp.scss";
import CourseModuleCmp from "../course.module/course.module.cmp";
import CourseOverviewCmp from "../course.overview/course.overview.cmp";




const CoursePlayer = ({selected, userName,state, isUserAdmin,onSelect, getTranscripts, showFeedbackForStudent, showCongrats, submitFeedback, recertifyAction, userCertificates}) => {

    const setRenderer=(selected)=>{
        if (!selected) return  null;
        switch (selected.type) {
            case "video":
                return <CourseVideoCmp selected={selected} getTranscripts={getTranscripts}/>
            case "quiz":
                return <CourseQuizCmp selected={selected} isUserAdmin={isUserAdmin}/>
            case "module":
                return <CourseModuleCmp selected={selected}/>
            case 'course':
                return <CourseOverviewCmp selected={selected} state={state} userName={userName} onSelect={onSelect} showFeedbackForStudent={showFeedbackForStudent} showCongrats={showCongrats}  submitFeedback={submitFeedback} recertifyAction={recertifyAction} userCertificates={userCertificates}/>
        }

    }

    return <div className="h-100">{setRenderer(selected)}</div>;
}
export default CoursePlayer;
