import React, { useState } from "react";
import "./mapbar.component.scss";
import navLogo from "../../assets/nav-logo.png";
import {
  AC_DOWNLOAD_MAP,
  AC_CHANGE_MONTH,
  AC_CHANGE_YEAR,
} from "../../common/Const";
import { useMediaQuery } from "react-responsive";
import { Select, Button, Text, Space } from "@mantine/core";

const NavbarComponent = ({
  onAction,
  year,
  month,
}: {
  onAction: any;
  year: string;
  month: string;
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1300px)" });

  return (
    <div className="cf-mapbar">
      <div className="cf-mapbar__links">
        <Text weight={500}>Select Month </Text>
        <Space w="md" />
        <Select
          placeholder="Pick month"
          value={month}
          onChange={(value) => {
            onAction(AC_CHANGE_MONTH, value);
          }}
          data={[
            { value: "All", label: "All" },
            { value: "1", label: "January" },
            { value: "2", label: "February" },
            { value: "3", label: "March" },
            { value: "4", label: "April" },
            { value: "5", label: "May" },
            { value: "6", label: "June" },
            { value: "7", label: "August" },
            { value: "8", label: "July" },
            { value: "9", label: "September" },
            { value: "10", label: "October" },
            { value: "11", label: "November" },
            { value: "12", label: "December" },
          ]}
        ></Select>
        <Space w="md" />
        <Text weight={500}>Select Year</Text>
        <Space w="md" />
        <Select
          placeholder="pick year"
          value={year}
          onChange={(value) => {
            onAction(AC_CHANGE_YEAR, value);
          }}
          data={[
            { value: "2021", label: "2021" },
            { value: "2022", label: "2022" },
            { value: "2023", label: "2023" },
            { value: "2024", label: "2024" },
          ]}
        ></Select>
        <Space w="md" />
        <Button onClick={() => onAction(AC_DOWNLOAD_MAP, null)}>
          Download Map
        </Button>
      </div>
    </div>
  );
};

export default NavbarComponent;
