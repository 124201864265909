class EventObservable {
  listeners: any;

  constructor() {
    this.listeners = {};
  }

  addEventListeners(event, observer) {
    if (!(event in this.listeners)) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(observer);
  }

  removeEventListeners(event, observer) {
    if (event in this.listeners) {
      var index = this.listeners[event].indexOf(observer);
      if (index > -1) {
        this.listeners[event].splice(index, 1);
      }

      if (this.listeners[event].length === 0) {
        delete this.listeners[event];
      }
    }
  }

  sendEvent(event, data?, sender?) {
    if (event in this.listeners) {
      var observer = this.listeners[event];
      for (var value of this.listeners[event]) {
        observer = value;
        if (this.isFunction(observer)) {
          observer(event, data);
        } else {
          (function (obj) {
            obj.process(event, data, sender);
          })(observer);
        }
      }
    }
  }

  isFunction(functionToCheck) {
    var getType = {};
    return (
      functionToCheck &&
      getType.toString.call(functionToCheck) === "[object Function]"
    );
  }
}

const instance = new EventObservable();
export default instance;
