import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";
export class ProtectedRoute extends Component<any, any> {
  render() {
    if (this.props.location && this.props.location.pathname === "/signup") {
      return null;
    }
    if (
      this.props.location &&
      this.props.location.pathname === "/activate" &&
      this.props.location.state &&
      this.props.location.state.password &&
      this.props.location.state.userName &&
      !this.props.activatedAccount
    ) {
      return null;
    }
    if (this.props.authorise && this.props.activatedAccount) {
      return <Route path={this.props.path} component={this.props.component} />;
    } else if (this.props.authorise && !this.props.activatedAccount) {
      return <Redirect to="/activate" />;
    } else {
      return <Redirect to="/login" />;
    }
  }
}

const mapProps = (state: any) => {
  return {
    authorise: state.application.authorise,
    activatedAccount: state.application.user
      ? state.application.user.emailVerified
      : null,
    isInitialising: state.application.loading,
  };
};
//@ts-ignore
const instance = connect(mapProps)(withRouter(ProtectedRoute));
export default instance;
