import React from "react";
import applicationComander from "../../../store/commanders/application.comander";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltLeft,
  faCircleUser,
} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "@mantine/form";
import { Box, Button, Group, Space, Text, TextInput } from "@mantine/core";
import { validateEmail } from "../../../helpers/form.validators";
import { URL_PATHS } from "../../../consts/url.paths.consts";
import { Icon } from "@fortawesome/fontawesome-svg-core";

type FormData = {
  email: string;
};

const ForgotPasswordPage = (props) => {
  const existsEmailValue =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.email
      ? props.location.state.email
      : null;

  const form = useForm({
    initialValues: {
      email: existsEmailValue,
    },

    validate: {
      email: validateEmail,
    },
  });

  const onSubmit = (data) =>
    applicationComander.cmd_forgot_password(data.email);
  return (
    <Box sx={{ maxWidth: 450 }} mx="auto" className="auth-container">
      <FontAwesomeIcon
        icon={faCircleUser as Icon}
        size={"5x"}
        className="auth-container--icon"
      />
      <Space h="xl" />
      <form
        onSubmit={form.onSubmit((values) => onSubmit(values))}
        className="u-w-100"
      >
        <TextInput
          required
          label="Email"
          placeholder="your@email.com"
          className="u-w-100"
          {...form.getInputProps("email")}
        />
        <Space h="md" />
        <Group mt="lg" mb="sm">
          <Button size="lg" type="submit" className="u-w-100">
            Send Code
          </Button>
        </Group>
        <Space h={"sm"}></Space>
        <Text
          size="sm"
          className="auth-container--link auth-container--link-darker"
          onClick={() =>
            applicationComander.cmd_redirect({ to: URL_PATHS.LOGIN })
          }
        >
          <FontAwesomeIcon icon={faLongArrowAltLeft as Icon} />
          <span className="u-ml-sm">Back to Log In</span>
        </Text>
      </form>
    </Box>
  );
};

export default ForgotPasswordPage;
