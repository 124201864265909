import { useForm } from "@mantine/form";
import {
  TextInput,
  Button,
  Group,
  Select,
  Space,
  NumberInput,
  InputWrapper,
  Checkbox,
  Title,
  MultiSelect,
  Textarea,
} from "@mantine/core";
import { createStyles } from "@mantine/core";
import { useEffect, useImperativeHandle, useState } from "react";
import React from "react";
import InputMask from "react-input-mask";
import { useId } from "@mantine/hooks";
import { DatePicker } from "@mantine/dates";
import ReactSelect from "react-select";
import { cloneDeep } from "lodash";

export const disabledTypes = {
  dependsOnAnotherFiled: "dependsOnAnotherFiled",
};
export const FormInputTypes = {
  TEXT: "text",
  TEXTAREA: "textarea",
  PHONE: "phone",
  LOOKUP_SELECT: "LOOKUP_SELECT",
  LOOKUP_MULTI_SELECT: "LOOKUP_SELECT",
  LOOKUP_SELECT_REACT: "LOOKUP_SELECT_REACT",
  LOOKUP_MULTI_SELECT_REACT: "LOOKUP_MULTI_SELECT_REACT",
  SPACE: "SPACE",
  DATE: "DATE",
  DATE_RANGE: "DATE_RANGE",
  NUMBER: "NUMBER",
  CHECKBOX: "CHECKBOX",
  TITLE: "TITLE",
};

const useStyles = createStyles((theme) => ({
  input: {
    flexBasis: "40%",
  },
  label: {
    marginBottom: "0.8rem",
  },
}));

const assigningFormItems = (formSettings, item, data?) => {
  formSettings.initialValues[item.name] = item.defaultValue
    ? item.defaultValue
    : "";

  if (
    data &&
    item.disabledCondition &&
    item.disabledCondition.type &&
    item.disabledCondition.type === disabledTypes.dependsOnAnotherFiled
  ) {
    item.disabled =
      data[item.disabledCondition.field] &&
      data[item.disabledCondition.field].length
        ? true
        : false;
  } else {
    item.disabled = false;
  }

  if (item.validator) {
    formSettings.validate[item.name] = item.validator;
  }
};

const formConfig = (config, data?) => {
  const formSettings = { initialValues: {}, validate: {} };
  config.forEach((item) => {
    if (item.type === FormInputTypes.SPACE) return;
    if (item.group) {
      item.group.forEach((nestedItem) => {
        assigningFormItems(formSettings, nestedItem, data);
      });
      return;
    }
    assigningFormItems(formSettings, item, data);
  });

  return formSettings;
};

const RenderFormElement = ({
  item,
  itemProps,
  inputClassName,
  labelClassName,
  lookups,
  creatables,
}) => {
  switch (item.type) {
    case FormInputTypes.TITLE:
      return (
        <div>
          <Space h="xl" />
          <Title order={3}>{item.label}</Title>
        </div>
      );
    case FormInputTypes.TEXT:
      return (
        <div style={item.style} className={item.className}>
          <TextInput
            label={item.label}
            placeholder={item.placeholder}
            {...itemProps}
            className={inputClassName}
            labelProps={labelClassName}
            disabled={item.disabled}
            type={item.intype ? item.intype : "text"}
            style={item.style ? item.style : null}
            maxLength={item.maxLength ? item.maxLength : null}
          />
        </div>
      );
    case FormInputTypes.NUMBER:
      return (
        <NumberInput
          label={item.label}
          placeholder={item.placeholder}
          {...itemProps}
          className={inputClassName}
          labelProps={labelClassName}
        />
      );
    case FormInputTypes.PHONE:
      return (
        <div style={item.style} className={item.className}>
          <PhoneNumberInput
            item={item}
            itemProps={itemProps}
            inputClassName={inputClassName}
          ></PhoneNumberInput>
        </div>
      );
    case FormInputTypes.LOOKUP_SELECT:
      return (
        <div style={{ position: "relative" }} className={item.className}>
          {item.creatable ? (
            <Button
              variant="white"
              style={{ position: "absolute", right: 0 }}
              onClick={creatables[item.name]}
            >
              Create new
            </Button>
          ) : null}

          <Select
            label={item.label}
            placeholder={item.placeholder}
            {...itemProps}
            data={
              lookups && lookups[item.lookup]
                ? lookups[item.lookup]
                : item.data
                ? item.data
                : []
            }
            autoComplete="off"
            searchable={true}
            onChange={(e) => {
              itemProps.onChange && itemProps.onChange(e);
              item.onChange && item.onChange(e);
            }}
            className={inputClassName}
            labelProps={labelClassName}
            disabled={item.disabled}
          />
        </div>
      );
    case FormInputTypes.TEXTAREA:
      return (
        <Textarea
          label={item.label}
          placeholder={item.placeholder}
          {...itemProps}
          className={inputClassName}
          labelProps={labelClassName}
          disabled={item.disabled}
        />
      );
    case FormInputTypes.LOOKUP_MULTI_SELECT:
      return (
        <div style={{ position: "relative" }}>
          <MultiSelect
            className={item.className}
            label={item.label}
            placeholder={item.placeholder}
            data={
              lookups && lookups[item.lookup]
                ? lookups[item.lookup]
                : item.data
                ? item.data
                : []
            }
            onChange={(e) => {
              itemProps.onChange && itemProps.onChange(e);
              item.onChange && item.onChange(e);
            }}
            disabled={item.disabled}
            autoComplete="off"
          />
        </div>
      );
    case FormInputTypes.LOOKUP_SELECT_REACT:
    case FormInputTypes.LOOKUP_MULTI_SELECT_REACT:
      return (
        <InputWrapper
          id={itemProps.name}
          required={itemProps.required}
          label={item.label}
          className={item.className}
          error={itemProps.error}
        >
          <ReactSelect
            {...itemProps}
            id={itemProps.name}
            placeholder={item.placeholder}
            options={
              lookups && lookups[item.lookup]
                ? lookups[item.lookup]
                : item.data
                ? item.data
                : []
            }
            isDisabled={item.disabled}
            getOptionValue={(option: any) =>
              item.isTextArray ? option.label : option.id
            }
            getOptionLabel={(option: any) =>
              option.label ? option.label : option.value
            }
            isMulti={item.type === FormInputTypes.LOOKUP_MULTI_SELECT_REACT}
            autoComplete="off"
          />
        </InputWrapper>
      );
    case FormInputTypes.CHECKBOX:
      return (
        <Checkbox
          label={item.label}
          placeholder={item.placeholder}
          {...itemProps}
          className={inputClassName}
          labelProps={labelClassName}
          checked={itemProps.value}
        />
      );

    case FormInputTypes.DATE:
      return (
        <DatePicker
          className={item.className}
          withAsterisk
          label={item.label}
          placeholder={item.placeholder}
          {...itemProps}
        />
      );
    // case FormInputTypes.DATE_RANGE:
    //   return (
    //     <DateRangePicker
    //       withAsterisk
    //       label={item.label}
    //       placeholder={item.placeholder}
    //       {...itemProps}
    //     />
    //   );

    case FormInputTypes.SPACE:
      return <div className={inputClassName}></div>;
    default:
      return null;
  }
};

const RenderFormItems = ({
  config,
  form,
  inputClassName,
  labelClassName,
  lookups,
  creatables,
}) => {
  return config.map((element) => {
    if (element.group) {
      return (
        <Group mt={element.mt ?? "sm"}>
          <RenderFormItems
            config={element.group}
            form={form}
            inputClassName={inputClassName}
            labelClassName={labelClassName}
            lookups={lookups}
            creatables={creatables}
          />
        </Group>
      );
    }
    return (
      <>
        <RenderFormElement
          item={element}
          itemProps={form.getInputProps(element.name)}
          inputClassName={inputClassName}
          labelClassName={labelClassName}
          lookups={lookups}
          creatables={creatables}
        />
      </>
    );
  });
};

const FormComponent = React.forwardRef(
  (
    {
      config,
      data,
      onSaveFunc,
      withGuard,
      name,
      lookups = {},
      extra = {},
      creatables = {},
      label,
    }: {
      config: any;
      data?: any;
      onSaveFunc?: (data) => void;
      withGuard?: boolean;
      name?: string;
      lookups?;
      extra?;
      creatables?;
      label?: string;
    },
    ref
  ) => {
    const { classes } = useStyles();
    const configClone = cloneDeep(config);

    const form = useForm(formConfig(configClone, data));

    useEffect(() => {
      if (data && data.id) {
        dataRefactor(data, configClone);
        form.setValues({ ...data });
      }
      form.resetTouched();
      return () => {
        form.reset();
      };
    }, [data, data?.id]);

    useImperativeHandle(ref, () => ({
      getFormValues: () => {
        form.validate();
        if (form.isValid()) {
          const values = formValuesDatesFormatter(form.values);
          formatFormValues(values, configClone);
          return {
            ...form.values,
            ...extra,
          };
        }
      },
      setValue: (field, value) => {
        form.setFieldValue(field, value);
      },
    }));

    // usePrompt(
    //   "Changes weren't saved, are you sure you want to leave?",
    //   withGuard && form && form.isTouched() ? true : false
    // );

    const onFormSubmitHandler = (vals) => {
      const values = formValuesDatesFormatter(vals);
      formatFormValues(values, configClone);
      onSaveFunc(values);
      form.resetTouched();
    };
    return (
      <div>
        <form onSubmit={form.onSubmit(onFormSubmitHandler)}>
          <Group position="right" mt="md" className="form-submit-btn">
            {onSaveFunc ? <Button type="submit">{label}</Button> : null}
          </Group>
          <RenderFormItems
            config={configClone}
            form={form}
            inputClassName={classes.input}
            labelClassName={classes.label}
            lookups={lookups}
            creatables={creatables}
          />
          <Space h="xl" />

          <Group position="right" mt="xl" className="form-submit-btn">
            {onSaveFunc ? <Button type="submit">{label}</Button> : null}
          </Group>
        </form>
      </div>
    );
  }
);

export default FormComponent;

const formatFormValues = (values, config) => {
  config.forEach((item) => {
    if (item.group) {
      formatFormValues(values, item.group);
      return;
    }
    if (item.format) {
      values[item.name] = item.format(values[item.name]);
    }
  });
};

const PhoneNumberInput = ({ item, inputClassName, itemProps }) => {
  const id = useId();
  return (
    <InputWrapper label={item.label} className={inputClassName} id={id}>
      <TextInput
        component={InputMask}
        mask="(999) 999-9999"
        placeholder={item.placeholder}
        id={id}
        {...itemProps}
      />
    </InputWrapper>
  );
};

// const DateRange = ({item, itemProps}) => {
//   return (
//     <DateRangePicker
//       withAsterisk
//       label={item.label}
//       placeholder={item.placeholder}
//       {...itemProps}
//     />
//   );
// };

export const formValuesDatesFormatter = (values) => {
  const newValues = {};

  for (const valueKey in values) {
    if (
      values[valueKey] &&
      values[valueKey].getMonth &&
      typeof values[valueKey].getMonth === "function"
    ) {
      newValues[valueKey] = values[valueKey].toDateString();
      continue;
    }
    newValues[valueKey] = values[valueKey];
  }

  return newValues;
};

const dataRefactor = (data, config) => {
  config.forEach((item) => {
    if (item.group) {
      dataRefactor(data, item.group);
      return;
    }
    if (item.type === FormInputTypes.DATE && data[item.name] && data[item.name].length) {
      try {
        data[item.name] = new Date(data[item.name]);
      } catch (e) {
        console.log(e);
      }
    }
  });
};
