import { Title, Group, Button, Space } from "@mantine/core";
import React, { useState, useEffect, useRef } from "react";
import { Checkbox } from "tabler-icons-react";
import FormComponent from "../form/form.component";
import { parentFormConfig } from "../form/parent-form-config";
import { URL_PATHS } from "../../consts/url.paths.consts";
import ApplicationComander from "../../store/commanders/application.comander";
import { ArrowBack } from "tabler-icons-react";
import eventManager from "../../helpers/event.manager";
import { HOST } from "../../settings/setting";
import axios from "axios";
import {
  hideProgress,
  showProgress,
} from "../../services/eventAction/progress.service";
import { loadLookups } from "../../helpers/lookup.helper";

export default function Account({ onEditHandler, data, label }) {
  const [lookups, setLookups] = useState({ counties: [], LK_SPORTS: [] });
  const formRef = useRef(null);
  const onStateChange = async (data, reset = true) => {
    try {
      showProgress();
      if (reset && formRef.current) {
        //Reset county
        formRef.current.setValue("county", null);
      }
      const response: any = await axios.post(`${HOST}/api/lookup/getCounties`, {
        state: data,
      });

      setLookups((prev) => ({ ...prev, counties: response.data }));
      hideProgress();
    } catch (error) {
      console.log(error);
    } finally {
      hideProgress();
    }
  };
  useEffect(() => {
    eventManager.addListener("state.change", onStateChange);
    onStateChange(data.state, false);

    loadLookups(parentFormConfig, {}, (data: Array<any>) => {
      setLookups((prev) => ({ ...prev, ...data }));
    });
  }, []);

  return (
    <div>
      {data.valid_subscription && data.enrolled ? (
        <div className="back-button-container">
          <Button
            leftIcon={<ArrowBack />}
            color="dark"
            onClick={() =>
              ApplicationComander.cmd_redirect({
                to: URL_PATHS.COURSE,
                state: { date: new Date() },
              })
            }
          >
            Back to Course
          </Button>
        </div>
      ) : null}

      <Space h="md" />

      <FormComponent
        config={parentFormConfig}
        onSaveFunc={onEditHandler}
        data={data}
        name={"Parent"}
        label={label}
        lookups={lookups}
        ref={formRef}
      />
    </div>
  );
}
