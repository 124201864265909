import React from 'react';

const BoolSurveyCmp = ({formRegister, error}) => {
    return (
        <>
            <div className="d-flex">
                <input{...formRegister} type="radio" value="Yes" id={`${formRegister.name}_Yes`} className={error ? "error-text-radio quiz-radiobox" :"quiz-radiobox"}/>
                <label htmlFor={`${formRegister.name}_Yes`}>Yes</label>
            </div>
            <div className="d-flex">
                <input{...formRegister} type="radio" value="No" id={`${formRegister.name}_No`} className={error ? "error-text-radio quiz-radiobox" :"quiz-radiobox"}/>
                <label htmlFor={`${formRegister.name}_No`}>No</label>
            </div>
        </>
    );
};

export default BoolSurveyCmp;