import React from "react";
import "./certificate.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import html2pdf from "html2pdf.js";

export const PrintableCertificate = ({
  courseStatus,
  userName,
  state,
  completedDate,
  className,
  hideIcon,
}: {
  courseStatus: string;
  userName: string;
  state: string;
  completedDate;
  className?: string;
  hideIcon?: boolean;
}) => {
  const print = () => {
    const element = document.getElementById(completedDate);
    const opt = {
      margin: 0,
      padding: 0,
      filename: "certificate.pdf",
      image: { type: "jpeg", quality: 0.98 },
      jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
    };

    html2pdf(element, opt);
  };

  return (
    <>
      <div className="cert-container">
        {/* <Certificate
          userName={userName}
          state={state}
          completedDate={completedDate}
        /> */}
        {state !== "AL" ? (
          <Certificate
            userName={userName}
            state={state}
            completedDate={completedDate}
          />
        ) : (
          <CertificateAL
            userName={userName}
            state={state}
            completedDate={completedDate}
          />
        )}
      </div>
      {courseStatus === "pass" ? (
        <button
          className={
            className
              ? "cf-navbar__links--link cf-navbar__links--link-primary " +
                className
              : "cf-navbar__links--link cf-navbar__links--link-primary"
          }
          onClick={() => print()}
        >
          {!hideIcon ? (
            <FontAwesomeIcon
              icon="certificate"
              size={"2x"}
              color="white"
              style={{ marginRight: 5 }}
            />
          ) : null}
          <div>Print Certificate</div>{" "}
        </button>
      ) : null}
    </>
  );
};

const Certificate = ({ userName, state, completedDate }) => {
  return (
    <div id={completedDate} className="centered-pdf">
      <div>
        <div>
          <div className={"bg-certificate bg-certificate-playsafely"}></div>
          <div
            style={{ position: "absolute", left: 522, top: 257 }}
            className="cls_003"
          >
            <span className="cls_003">{userName}</span>
          </div>
          <div
            style={{ position: "absolute", left: 522, top: 574 }}
            className="cls_004"
          >
            <span className="cls_004">{completedDate}</span>
          </div>
          {/*<div style={{position: 'absolute', left: 773, top: 491}} className="cls_005">*/}
          {/*    <span className="cls_005">Date</span>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

const CertificateAL = ({ userName, state, completedDate }) => {
  return (
    <div id={completedDate} className="centered-pdf">
      <div>
        <div>
          <div className="bg-certificate-coachsafely"></div>
          {/*<div>*/}
          {/*    style={{position: 'absolute', left: 480, top: 293.25}}*/}
          {/*    className="cls_002"*/}
          {/*>*/}
          {/*    <span className="cls_002">This is to certify that</span>*/}
          {/*</div>*/}
          <div
            style={
              state === "AL"
                ? { position: "absolute", left: 480.35, top: 275 }
                : { position: "absolute", left: 480.35, top: 300 }
            }
            className="cls_003"
          >
            <span className="cls_003">{userName}</span>
          </div>
          {/*            <div*/}
          {/*                style={{position: 'absolute', left: 397.85, top: 347.25}}*/}
          {/*                className="cls_002"*/}
          {/*            >*/}
          {/*<span className="cls_002"*/}
          {/*>has satisfactorily completed the requirement for the</span*/}
          {/*>*/}
          {/*            </div>*/}
          {/*            <div*/}
          {/*                style={{position: 'absolute', left: 393.25, top: 376.5}}*/}
          {/*                className="cls_003"*/}
          {/*            >*/}
          {/*                <span className="cls_003">Certificate Program in CoachSafely</span>*/}
          {/*            </div>*/}
          <div
            style={
              state === "AL"
                ? { position: "absolute", left: 480.35, top: 360 }
                : { position: "absolute", left: 480.35, top: 380 }
            }
            className="cls_004"
          >
            <span className="cls_004">{completedDate}</span>
          </div>
          {/*<div style={{position: 'absolute', left: 773, top: 491}} className="cls_005">*/}
          {/*    <span className="cls_005">Date</span>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};
export default Certificate;
