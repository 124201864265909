import axios from "axios";
import {toast} from "react-toastify";

export const FetchTranscripts = async (lesson) => {
    try {
        const result = await axios(
            lesson.url.replace('mp4', 'json')
        );
        return result.data.items.map(word => ({...word, start_time: Number(word.start), end_time: Number(word.end)}))
    } catch (e) {
        toast.error('Error loading transcripts');
        return null
    }

}