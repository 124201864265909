import React, { useState } from "react";
import "./dropdown.menu.component.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OutsideClickHandler from "react-outside-click-handler";

const DropdownMenuHOC = (PassedInComponent, dropDownOptions) => (props) => {
  const DropdownMenuHOC = (props) => {
    const [open, setOpen] = useState(false);

    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          if (open) setOpen(false);
        }}
      >
        <div
          className="dropdown"
          onClick={() => setOpen(!open)}
        >
          <div className="cursor-pointer">
            <PassedInComponent {...props} />
          </div>

          <div className="dropdown__menu" hidden={!open}>
            {dropDownOptions
              ? dropDownOptions.map((option, index) => {
                  if (option.onlyAdmin && !props.isAdmin) return;
                  if (option.requireSubscription && props.disabled) return;
                  return (
                    <div
                      className="dropdown__menu--item"
                      onClick={
                        option.navigateTo
                          ? option.out
                            ? () => window.open(option.navigateTo, "_blank")
                            : () => props.history.push(option.navigateTo)
                          : option.onClick
                      }
                      key={index}
                    >
                      <FontAwesomeIcon
                        icon={
                          typeof option.icon === "string"
                            ? option.icon
                            : option.icon(props)
                        }
                        className="icon"
                      />
                      {typeof option.title === "string"
                        ? option.title
                        : option.title(props)}
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </OutsideClickHandler>
    );
  };

  return DropdownMenuHOC;
};

export default DropdownMenuHOC;
