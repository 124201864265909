import { combineReducers } from "redux";
import { application } from './reducers/application.reducer'
import { course } from './reducers/course.reducer'
import { account } from "./reducers/account.reducer";
import { coupon } from "./reducers/coupon.reducer";
export default combineReducers({
    application,
    course,
    account,
    coupon
});
