import React, {Component} from 'react'
import TreeComponent from '../../common/tree/tree.component'
import "./course.tree.cmp.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const treeStyles = {
    position: 'relaive',
    top: 40,
    left: 40,
    color: 'black',
    fill: 'white',
    width: '100%',
    fontSize: '14px',
    // overflow:'auto'

}


export default class CourseTreeCmp extends Component<any, any> {
    constructor(props) {
        super(props)
        this.state = {selected: null}
    }

    onInternalSelect = (e, item) => {
        e.stopPropagation()
        const {onSelect} = this.props;
        if (onSelect) onSelect(item)
    }

    getIcon = (type: string): any => {
        switch (type) {
            case "video":
                return 'video'
            case "quiz":
                return 'vial'
            case "course":
                return 'book'
            case "module":
                return 'book'
        }
    }


    renderItem = (item) => {
        const {selected} = this.props;
        const isSelected = selected == item;
        return <div key={item.id}
                    className={isSelected ? "course-outline-container course-outline-selected" : "course-outline-container"}>
            <div className="course-menu-button-container">
                <FontAwesomeIcon icon={this.getIcon(item.type)} size={"lg"} color={item.color}/>
            </div>
            <div className="course-info-container">
                <div className="course-outline-text-container">{item.type === 'quiz' ? (
                    <>
                        Quiz
                        <span className="ml-2">
                    ({item.grades === 0 ? (0 / item.children.length) : item.grades * item.children.length / 100}/{item.children.length})
                </span>
                    </>) : (item.title)}  </div>

                {item.children && item.type === 'course' ? (<div
                    className="course-outline-text-container"> ({item.progress === 0 ? (0 / item.children.length) : Math.floor(item.progress * item.children.length / 100)}/{item.children.length})
                </div>) : null}

            </div>
        </div>
    }


    renderTree = (item) => {

        if (!item || !item.id) return <></>
        const children = item.type === 'quiz' ? null : item.children
        return <div key={item.id}>
            <TreeComponent className="table-tree" item={item}
                           onSelect={(e) => this.onInternalSelect(e, item)}
                           renderItem={this.renderItem}
                           selected={this.state.selected ? this.state.selected == item : false}
                           canDrag={false}
                           dropable={false}
                           completed={this.isComplete(item)}
                           icon="file-alt"
                           iconColor="green" open style={treeStyles}>
                {children ? children.map((item) => this.renderTree(item)) : null}
            </TreeComponent>
        </div>
    }

    isComplete = (item) => {
        return item.type === 'quiz' ? item.status == 'pass' : item.finish;
    }

    render() {
        const {item} = this.props;
        return <div className="main-class-editor outline-container" tabIndex={0}>
            {this.renderTree(item)}
        </div>
    }

}
