export const searchTree = (row, id) => {
    if (row.id == id) {
        return row;
    } else if (row.children != null) {
        let result = null;
        for (let i = 0; result == null && i < row.children.length; i++) {
            result = searchTree(row.children[i], id);
        }
        return result;
    }
    return null;
}

export const getNextNode = (row, id,parent) => {
    if (row.id == id) {
        //next module
        if(row.type === 'quiz') {
            const rowIndex = parent.parent.children.findIndex((el) => el.id === parent.id)
            if (parent.parent.children[rowIndex+1]) return parent.parent.children[rowIndex+1]
            if (!parent.parent.children[rowIndex+1]) return parent.parent
        }
        if(row.children && row.children.length) return row.children[0]
        const rowIndex = parent.children.findIndex((el) => el.id === row.id)
        if (parent.children[rowIndex+1]) return parent.children[rowIndex+1]
        return row;
    } else if (row.children != null && row.type !== 'quiz') {
        let result = null;
        for (let i = 0; result == null && i < row.children.length; i++) {
            result = getNextNode(row.children[i], id, {...row, parent});
        }
        return result;
    }
    return null;
}


export const updateRowInTree = (data, id, newNode) => {
    if (data.id == id) {
        data = newNode;
    }
    if (data.children !== undefined && data.children.length > 0) {
        for (let i = 0; i < data.children.length; i++) {
            data.children[i] = updateRowInTree(data.children[i],id, newNode);
        }
    }

    return data;
}


export const updatePropertyById =(id, data, property, value) =>{
    if (data.id == id) {
        data[property] = value;
    }
    if (data.children !== undefined && data.children.length > 0) {
        for (let i = 0; i < data.children.length; i++) {
            data.children[i] = updatePropertyById(id, data.children[i], property, value);
        }
    }

    return data;
}