import React from 'react';
import './model.cmp.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import OutsideClickHandler from 'react-outside-click-handler';
import { Icon } from '@fortawesome/fontawesome-svg-core';

const ModelCmp = ({ name, bodyComponent, onCancel, onSend }) => {
    return (
        <>
            <div className="overlay"></div>
            <OutsideClickHandler
                onOutsideClick={() => {
                    onCancel()
                }}
            >
                <div className="model">
                    <div className="model__header">
                        {name ? (
                            <span>{name}</span>

                        ) : null}

                        <span className="ml-auto cursor-pointer" onClick={onCancel}>
                            <FontAwesomeIcon icon={faTimes as Icon} />
                        </span>
                    </div>

                    <div className="model__body">
                        {bodyComponent}
                    </div>

                    <div className="model__footer">
                        <button onClick={onCancel} className="cf-navbar__links--link mr-3">Cancel</button>
                        <button onClick={onSend} className="cf-navbar__links--link cf-navbar__links--link-primary">Send
                        </button>
                    </div>
                </div>
            </OutsideClickHandler>
        </>
    );
};

export default ModelCmp;