import React, { useEffect, useRef, useState } from "react";
import Map from "../../components/map/Map";
import { MantineProvider } from "@mantine/core";
//import "./App.css";
import { AC_DOWNLOAD_MAP, AC_CHANGE_MONTH, AC_CHANGE_YEAR } from '../../common/Const'


import MapBarComponent from "../../components/mapbar/mapbar.component";
const MAX_IMAGE_SIDE = 200;

export default function MapPage() {
  const mapRef: any = useRef(null);
  const [month, setMonth] = useState('All')
  const [year, setYear] = useState('2022')
  const onNavAction = (action: string, value: string) => {
    if (action === AC_DOWNLOAD_MAP)
      mapRef.current.downloadScreen();
    if (action === AC_CHANGE_MONTH)
      setMonth(value);
    if (action === AC_CHANGE_YEAR)
      setYear(value);

  }
  const renderMain = () => {

    return (
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <div className="App">

          <MapBarComponent onAction={onNavAction} month={month} year={year} />
          <div className="mapContainer">
            <Map ref={mapRef} data={null} onSelect={(item: any) => { }} month={month} year={year} />;
          </div>
          <div>{/* <FooterComponent /> */}</div>
        </div>
      </MantineProvider>
    );
  };

  return renderMain();
}
