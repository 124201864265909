import Actions from "../consts";

export const coupon = (state = { data: [] }, action: any = {}) => {
  switch (action.type) {
    case Actions.GET_COUPONS:
      return { ...state, data: action.payload };
    case Actions.CREATE_COUPON:
      return { ...state, data: [...state.data, action.payload] };
    case Actions.UPDATE_COUPON:
      const updateIndex = state.data.findIndex(
        (item) => item.id == action.payload.id
      );
      const updatedata = [...state.data];
      updatedata[updateIndex] = action.payload;
      return { ...state, data: updatedata };
    case Actions.DELETE_COUPON:
      const deletedata = state.data.filter(
        (item) => item.id !== action.payload.id
      );
      return { ...state, data: deletedata };
    default:
      return state;
  }
};
