import React, { useRef, useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import debounce from "lodash.debounce";
import { CouponCommander } from "../../store/commanders/coupon.commander";
import { useSelector } from "react-redux";
import ModelCmp from "../../components/model/model.cmp";
import CouponFormCmp from "../../components/coupon/coupon.modal";
import BtnCellRenderer from "./renderer/button.renderer";

import "./coupon.scss";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const Coupon = () => {
  const gridRef = useRef();
  const modalFormRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);
  const [selected, setSelected] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [count, setCount] = useState(0);
  const rowData = useSelector((state: any) => state.coupon.data);

  useEffect(() => {
    getData("");
  }, []);

  const getData = async (term) => {
    CouponCommander.cmd_get_coupons(term);
  };

  const debouncedGetData = debounce((term) => getData(term), 350);

  const [columnDefs] = useState([
    { field: "coupon_key", headerName: "Coupon", width: 150 },
    // { field: 'user.account_email', headerName: 'Organization Admin', filter: true, width: 180, },
    // { field: 'user.first_name', headerName: 'Org Admin First Name', filter: true, width: 180, },
    // { field: 'user.last_name', headerName: 'Org Admin Last Name', filter: true, width: 180, },
    {
      field: "coupon_admin_email",
      headerName: "User email",
      filter: true,
      width: 180,
    },
    { field: "coupon_discount", headerName: "Discount", width: 150 },
    { field: "units", headerName: "Units", filter: true, width: 150 },
    { field: "coupon_usage", headerName: "Redeemed", filter: true, width: 150 },
    {
      field: "coupon_usage",
      headerName: "Send Email",
      cellRenderer: "btnCellRenderer",
      cellRendererParams: {
        clicked: function (data) {
          //Send
          CouponCommander.cmd_send_coupon(data);
        },
      },
    },
  ]);

  const onSelectionChanged = () => {
    const selectedRows = gridApi.getSelectedRows();
    setSelected(selectedRows[0]);
  };

  const onGridDblClick = () => {
    const selectedRows = gridApi.getSelectedRows();
    setSelected(selectedRows[0]);
    setShowModal(true);
  };

  const gridOptions = {
    suppressMenuHide: true,
    rowSelection: "single",
    frameworkComponents: {
      btnCellRenderer: BtnCellRenderer,
    },
  };

  const searchInput = useRef(null);

  const defaultColDef = {
    filter: "agTextColumnFilter",
    sortable: true,
    unSortIcon: true,
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const createCoupon = () => {
    setSelected(null);
    setShowModal(true);
  };

  const deleteCoupon = () => {
    if (!selected) return;
    CouponCommander.cmd_delete_coupon(selected);
  };

  return (
    <div className="account marks-bg">
      <div className="account__body">
        <div className="grid-container">
          <div className="quick-filter">
            <input
              type="search"
              onChange={(e) => debouncedGetData(e.target.value)}
              placeholder="search ..."
              className="quick-filter--input"
              ref={searchInput}
            />
            <button
              className="login-button"
              style={{ width: 200, marginLeft: 20 }}
              onClick={createCoupon}
            >
              Add Coupon
            </button>
            <button
              className="login-button"
              disabled={!selected}
              style={{ width: 200, marginLeft: 20 }}
              onClick={deleteCoupon}
            >
              Delete Coupon
            </button>
          </div>
          <div className="ag-theme-alpine grid-container__grid">
            {showModal ? (
              <ModelCmp
                name={"Coupon Information"}
                bodyComponent={
                  <CouponFormCmp
                    innerRef={modalFormRef}
                    afterSubmitting={() => setShowModal(false)}
                    selected={selected}
                    onSubmit={() => {}}
                  />
                }
                onCancel={() => setShowModal(false)}
                onSend={() =>
                  modalFormRef.current.dispatchEvent(
                    new Event("submit", {
                      cancelable: true,
                      bubbles: true,
                    })
                  )
                }
              />
            ) : null}
            <AgGridReact
              onGridReady={onGridReady}
              onSelectionChanged={onSelectionChanged}
              onRowDoubleClicked={onGridDblClick}
              ref={gridRef}
              rowData={rowData ? rowData : []}
              gridOptions={gridOptions}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              suppressMenuHide={true}
            ></AgGridReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coupon;
