const Actions = {
    AUTHORISE: "AUTHORISE",
    UN_AUTHORISE: "UN_AUTHORISE",
    REDIRECT: "REDIRECT",
    GET_COURSE: "GET_COURSE",
    SET_COURSE_SECTION: "SET_COURSE_SECTION",
    MARK_LESSON_FINISH: "MARK_LESSON_FINISH",
    GO_LAST_SEEN: "GO_LAST_SEEN",
    NEXT_LESSON: "NEXT_LESSON",
    SUBMIT_QUIZ: "SUBMIT_QUIZ",
    UPDATE_LAST_SEEN: "UPDATE_LAST_SEEN",
    GET_ACCOUNT: "GET_ACCOUNT",
    UPDATE_ACCOUNT: "UPDATE_ACCOUNT",
    APP_LOADING: "APP_LOADING",
    RETRY_QUIZ: "RETRY_QUIZ",
    GET_COUPONS: "GET_COUPONS",
    CREATE_COUPON: "CREATE_COUPON",
    UPDATE_COUPON: "UPDATE_COUPON",
    DELETE_COUPON: "DELETE_COUPON",
    ASK_FEEDBACK: "ASK_FEEDBACK",
    FEEDBACK_SUBMITTED: 'FEEDBACK_SUBMITTED',
    GET_CERTIFICATES: "GET_CERTIFICATES",
    TOGGLE_NOTIFICATIONS: "TOGGLE_NOTIFICATIONS",
    GET_EMAIL:"GET_EMAIL"
};
export default Actions;
