import Store from "../store/store";
import Const from "../store/consts";
import axios, { Method } from 'axios'
import SerializationManager from '../domain/serialization.manager'
import { getToken } from "../services/auth/cognito.service";

export const authFecth = (url: string, method: Method, data?: any, contentType?: string, nojwt?: boolean) => {

    const serialize_data: any = serialize(data);
    return new Promise(async (resolve, reject) => {
        const token = await getToken()
        axios({
            method: method,
            url: url,
            headers: !nojwt ? {
                Authorization: token,
                "Content-Type": contentType ? contentType : "application/json"
            } : {
                "Content-Type": contentType ? contentType : "application/json"
            },
            data: serialize_data
        })
            .then((response) => {
                if (response.status === 401) {
                    Store.dispatch({
                        type: Const.UN_AUTHORISE,
                        payload: {},
                    });
                    reject();
                } else {
                    resolve(SerializationManager.deserialize(response.data));
                }
            })
            .catch((error) => {
                console.log(error.response.data);
                reject({ message: error.response.data });
            });
    });
};

const serialize = (data: any): any => {
    if (!data) return data;

    if (data.serialize != null) {
        return data.serialize();
    }
    return data;
}
