import { Title, Space, Container } from "@mantine/core";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Account from "../../components/account/account";
import GridComponent from "../../components/grid/grid.component";
import { PARENT_PLAYERS_HEADERS } from "../../consts/grid.columns";
import { URL_PATHS } from "../../consts/url.paths.consts";
import { AccountCommander } from "../../store/commanders/account.commander";
import ApplicationComander from "../../store/commanders/application.comander";
import { ArrowBack } from "tabler-icons-react";
import "./account.scss";
import { PrintableCertificate } from "../../components/certficate/certificate";
export default function AccountPage() {
  const userData = useSelector((state: any) => state.account.userData);

  const userCourseSession = useSelector((state: any) => state.course.data);
  const onEditHandler = (data) => {
    AccountCommander.cmd_update_account({ ...userData, ...data });
  };
  const userName = userData.first_name + " " + userData.last_name;

  return (
    <Container size={"xl"} className="u-w-100">
      <PrintableCertificate
        courseStatus={userCourseSession.status}
        userName={userName}
        state={userData.state}
        completedDate={userCourseSession.last_completion}
        className="print-certificate-button"
      />

      <Space h="md"></Space>

      <Account
        data={userData}
        onEditHandler={onEditHandler}
        label={"Update Information"}
      ></Account>
      <Space h={"xl"} />
      {userData.players_list &&
      userData.players_list.length &&
      userData.players_list[0].id ? (
        <>
          <Title order={2}>Players </Title>
          <GridComponent
            rows={userData.players_list}
            headers={PARENT_PLAYERS_HEADERS}
            showControls={false}
          ></GridComponent>
        </>
      ) : (
        <div style={{ padding: "40px 0" }}></div>
      )}
    </Container>
  );
}
