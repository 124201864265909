class SerializationManager {
    serializers: any = {}
    register = (type: string, func: Function) => {
        this.serializers[type] = func;
    }

    deserialize = (data: any) => {
        if (!data) return data;
        if (data.hasOwnProperty('type')) {
            const serializer = this.serializers[data['type']]
            if (serializer)
                return serializer(data)
        } else {
            const keys = Object.keys(data)
            for (const key of keys) {
                if (Array.isArray(data[key])) {
                    data[key] = data[key].map(item => this.deserialize((item)))
                }
            }
        }
        return data;
    }
}

const instnance = new SerializationManager();
export default instnance
