import { toast } from "react-toastify";
import { authFecth } from "../../helpers/authFecth";
import { HOST } from "../../settings/setting";
import { progressAction } from "../helper/action.helper";
import { ac_get_account } from "./account.action";
import Const from "../consts";
import { ac_get_course } from "./course.action";

export const ac_get_coupons =
  (term: string) => async (dispatch: any, getState: any) => {
    progressAction(async () => {
      try {
        const response: any = await authFecth(
          `${HOST}/api/coupon/search`,
          "post",
          { term: term }
        );
        dispatch({
          type: Const.GET_COUPONS,
          payload: response,
        });
      } catch (e) {
        toast.error(e.message);
      }
    });
  };

export const ac_create_coupon =
  (coupon) => async (dispatch: any, getState: any) => {
    progressAction(async () => {
      try {
        const response: any = await authFecth(
          `${HOST}/api/coupon`,
          "post",
          coupon
        );
        dispatch({
          type: Const.CREATE_COUPON,
          payload: response,
        });
        toast.info("Your Coupon has been created");
      } catch (e) {
        toast.error(e.message);
      }
    });
  };

export const ac_update_coupon =
  (coupon) => async (dispatch: any, getState: any) => {
    progressAction(async () => {
      try {
        const response: any = await authFecth(
          `${HOST}/api/coupon`,
          "put",
          coupon
        );
        dispatch({
          type: Const.UPDATE_COUPON,
          payload: response,
        });
        toast.info("Your Coupon has been updated");
      } catch (e) {
        toast.error(e.message);
      }
    });
  };

export const ac_delete_coupon =
  (coupon) => async (dispatch: any, getState: any) => {
    progressAction(async () => {
      const response: any = await authFecth(
        `${HOST}/api/coupon`,
        "delete",
        coupon
      );
      try {
        dispatch({
          type: Const.DELETE_COUPON,
          payload: coupon,
        });
      } catch (e) {
        toast.error(e.message);
      }
    });
  };

export const ac_validate_coupon = async (coupon_key: string) => {
  const response: any = await authFecth(`${HOST}/api/coupon/validate`, "put", {
    coupon_key: coupon_key,
  });
  if (response && response.error) toast.error(response.error);
  return response;
};

export const ac_redeem_coupon =
  (data) => async (dispatch: any, getState: any) => {
    progressAction(async () => {
      const response: any = await authFecth(
        `${HOST}/api/coupon/reedemCoupon`,
        "put",
        data
      );
      if (response && response.error) {
        if (response.forceRefresh) {
          dispatch(ac_get_account());
          return;
        }
        toast.error(response.error);
        return;
      }

      dispatch(ac_get_account());
      dispatch(ac_get_course());

      return response;
    });
  };

export const ac_send_coupon =
  (coupon) => async (dispatch: any, getState: any) => {
    progressAction(async () => {
      try {
        const response: any = await authFecth(
          `${HOST}/api/coupon/sendCoupon`,
          "put",
          coupon
        );
        toast.info("Your Coupon has been send");
      } catch (e) {
        toast.error(e.message);
      }
    });
  };
